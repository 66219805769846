<template>
    <div class="contacto">
      <h3 v-if="idioma == 'Español'">✨ Disponible para proyectos ✨</h3>
      <h3 v-if="idioma == 'Inglés'">✨ Available for projects now ✨</h3>
      <h4 v-if="idioma == 'Español'">
        Dame un toque para saber más o contratar mis servicios. <br /> <br />
        <span>📬<u> <a href="mailto:please@vicdesign.me"> please@vicdesign.me</a></u></span> | 
        <span>📱<u> <a href="tel:+34722340710">  +34 722340710</a></u></span>
      </h4>

      <h4 v-if="idioma == 'Inglés'">
        Feel free to contact me to know more or hire my services.<br /> <br />
        <span>📬<u> <a href="mailto:please@vicdesign.me"> please@vicdesign.me</a></u></span> | 
        <span>📱<u> <a href="tel:+34722340710">  +34 722340710</a></u></span>
      </h4>
    </div>
</template>

<script>
    export default {
        name:'Contact',
        data:function(){
            return{
                spanish:false,
            }
        },
          props: ['idioma'],
    }
</script>

<style lang="scss" scoped>

.contacto {
  border-top: solid 1px;
  border-top-color: rgb(212, 212, 212);
  padding-top: 48px;
  a{
    color: #333;
  }
  a:hover{
  color: #30c881;
}
  margin: 4rem 0;
  margin-bottom: 8rem;
  h3 {
    font-weight: 400;
    text-align: left;
    font-size: 6vw;
    line-height: 160%;
    span {
      font-weight: 500;
    }
  }
  h4 {
    font-weight: 400;
    text-align: left;
    font-size: 1rem;
    line-height: 160%;
    span {
      font-weight: 500;
    }
  }

}
@media (min-width: 500px) {
      .contacto {
        margin: 4rem 0;
    h3 {
      width: 80vw;
      font-size: 1.8rem;
    }


  }
}

@media (min-width: 1024px) {

      .contacto {
    h3 {
      width: 80vw;
      font-size: 2.7vw;
    }

        h4 {
    font-weight: 400;
    text-align: left;
    font-size: 1.5rem;
    line-height: 160%;
    span {
      font-weight: 500;
    }
  }
  }

    .contacto {
    h3 {
      font-size: 2rem;
      max-width: 1000px;
    }
  }
}


</style>