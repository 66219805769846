<template>
  <div id="app">
    <div v-if="this.transicion==true"><Transicion/></div>
  
    <!-- <Fly v-bind:idioma="this.idioma"></Fly> -->
    <Programar v-bind:idioma="this.idioma" />
    <!-- Modal -->
    <div class="modalMenu" v-if="modal.isActive">
      <Modal @changeModal="openNav" v-bind:idioma="this.idioma" />
    </div>
    <!--  fin - Modal -->
    <div class="contenido">
      <div id="nav">
        <div class="idioma" v-on:click="cambiaIdioma">
          <u>{{ iconoIdioma.idiomaSiguiente }}</u>
        </div>
        <svg
          v-if="!modal.isActive"
          class="hamburger"
          v-on:click="openNav"
          width="28"
          height="20"
          viewBox="0 0 28 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="28" height="4" />
          <rect y="8" width="28" height="4" />
          <rect y="16" width="28" height="4" />
        </svg>
        <svg 
          v-if="modal.isActive"
          class="hamburger"
          v-on:click="openNav"
          width="28"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.686279"
            y="20.4853"
            width="28"
            height="4"
            transform="rotate(-45 0.686279 20.4853)"
            fill="#f0f0f0"
          />
          <rect
            x="20.4853"
            y="23.3137"
            width="28"
            height="4"
            transform="rotate(-135 20.4853 23.3137)"
            fill="#f0f0f0"
          />
        </svg>
      </div>

      <!-- Nav hamburguer -->
      <router-view v-bind:idioma="this.idioma" v-bind:Activartransicion="this.Activartransicion"  @emisario="ToggleTransition"/>
    </div>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import Fly from "@/components/Fly.vue";
import Programar from "@/components/Programar.vue";
import Transicion from "@/components/Transicion.vue"


export default {
  data: function() {
    return {
      transicion:false,
      modal: {
        isActive: false,
      },
      colorHamburger: "#f0f0f0",
      idioma: "Español",
      iconoIdioma: {
        idiomaActual: "ES",
        idiomaSiguiente: "EN",
      },
      ruta: window.location.hash,
    };
  },
  components: {
    Modal,
    Fly,
    Programar,
    Transicion
  },

  methods: {
    
    openNav: function(ev) {
      this.modal.isActive = !this.modal.isActive;
    },
    cambiaIdioma: function(ev) {
      //console.log('he sido pulsado')
      if (this.iconoIdioma.idiomaActual == "ES") {
        this.idioma = "Inglés";
        this.iconoIdioma.idiomaActual = "EN";
        this.iconoIdioma.idiomaSiguiente = "ES";
      } else if (this.iconoIdioma.idiomaActual == "EN") {
        this.idioma = "Español";
        this.iconoIdioma.idiomaActual = "ES";
        this.iconoIdioma.idiomaSiguiente = "EN";
      }
    },
    ToggleTransition:function(ev){
      console.log('hellooo')
      this.transicion=true;
      setTimeout(() => {
        this.transicion=false;
      }, 4000);
    },
     Activartransicion:function(ev){
       this.ToggleTransition()
      // console.log(this.transicion)
      // this.$emit("emisario")
    },

  },
  watch: {
    
    
  },

  created() {

    console.log('%c Por cierto, también desarrollo, no es mi rol principal, pero me lo paso bien.', 'background: #222; color: #bada55');
  },
};
</script>

<style lang="scss">
@import "./scss/variables.scss";

html {
  scroll-behavior: smooth;
}

body {
  background: $primaryWhite;
  z-index: 1;
  margin: 0;
}
#app {
  background: $primaryWhite;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $primaryBlack;
  overflow-x: hidden;


}

.contenido {
  padding: 16px;
  z-index: 1;
}

.modalMenu {
  position: fixed;
  width: 100vw;
  height: 100%;
  background-color: $primaryBlack;
  z-index: 11;
  overflow-y: scroll;
}
#nav {
  display: flex;
  fill: $primaryWhite;
  mix-blend-mode: difference;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 11;
  padding: 30px;
  color: $primaryWhite;
  .idioma {
    cursor: pointer;
  }

  .hamburger {
    margin-left: 2rem;

    cursor: pointer;

    svg {
    }
  }

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }

}


</style>