<template>
    <div>
        <div class="fly on">
        <img  v-if="idioma =='Español'" src="../assets/fly.svg" alt="">
        <img  v-if="idioma =='Inglés'" src="../assets/fly-en.svg" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Fly',
        props:['idioma']
    }
</script>

<style lang="scss">
.fly{
z-index: 10;
position: fixed;
bottom:0;
right: -850px;
animation: avioncito 20s linear 2;

.on{
    
}
}
img{
    width:600px;
    
}

@keyframes avioncito{
    
    100%{
        right:100vw;
    
        
    }
}

@media (min-width: 600px) {


}


</style>