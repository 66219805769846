<template>
    <div>
<div class="modal">
    <div class="flex">
<div class="navbar">
    <ul v-if="idioma == 'Español'">
        
        <li @click="modalOff"><router-link to="/#top" >Home</router-link></li> 
        <li @click="modalOff"><router-link to="/#resume">Sobre mi</router-link></li>
        <li @click="modalOff"><router-link to="/#portfolio">Portfolio</router-link></li>
        <li @click="modalOff"><router-link to="/#contact">Contacto</router-link></li>
        

    </ul>

    <ul v-if="idioma == 'Inglés'">
        
       <li @click="modalOff"><router-link to="/#top" >Home</router-link></li> 
        <li @click="modalOff"><router-link to="/#resume">About me</router-link></li>
        <li @click="modalOff"><router-link to="/#portfolio">Portfolio</router-link></li>
        <li @click="modalOff"><router-link to="/#contact">Contact</router-link></li>
       

    </ul>

</div>
<!-- <div class="flecha"><svg width="16" height="104" viewBox="0 0 16 104" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.70711 0.292892C8.31658 -0.0976334 7.68342 -0.0976334 7.29289 0.292892L0.928933 6.65685C0.538409 7.04738 0.538409 7.68054 0.928933 8.07107C1.31946 8.46159 1.95262 8.46159 2.34315 8.07107L8 2.41422L13.6569 8.07107C14.0474 8.46159 14.6805 8.46159 15.0711 8.07107C15.4616 7.68054 15.4616 7.04738 15.0711 6.65685L8.70711 0.292892ZM9 104L9 1L7 1L7 104L9 104Z" fill="#F0F0F0"/>
</svg>
</div> -->

<div class="socials">
    <a href="/aviso-legal">Aviso legal</a>
    <a href="/cookies">Cookies</a>
    
    <a href="https://twitter.com/vff1991" target="_blank">Twitter</a>
    <!-- <div class="twitter">
        <a href="https://twitter.com/vff1991" target="_blank">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path d="M24 4.55699C23.117 4.94899 22.168 5.21299 21.172 5.33199C22.189 4.72299 22.97 3.75799 23.337 2.60799C22.386 3.17199 21.332 3.58199 20.21 3.80299C19.313 2.84599 18.032 2.24799 16.616 2.24799C13.437 2.24799 11.101 5.21399 11.819 8.29299C7.728 8.08799 4.1 6.12799 1.671 3.14899C0.381 5.36199 1.002 8.25699 3.194 9.72299C2.388 9.69699 1.628 9.47599 0.965 9.10699C0.911 11.388 2.546 13.522 4.914 13.997C4.221 14.185 3.462 14.229 2.69 14.081C3.316 16.037 5.134 17.46 7.29 17.5C5.22 19.123 2.612 19.848 0 19.54C2.179 20.937 4.768 21.752 7.548 21.752C16.69 21.752 21.855 14.031 21.543 7.10599C22.505 6.41099 23.34 5.54399 24 4.55699Z" fill="#F0F0F0"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>

</svg>
</a>

    </div> -->

   
</div>
</div>
</div>
    </div>
</template>

<script>
    export default {
       name: 'Modal',
       methods:{
           modalOff(){
               this.$emit('changeModal')
               console.log('mensaje emitido')
              let target = document.getElementById(window.location.hash.toString().split("").splice(1).join(""))
              
              setTimeout(() => {
                  target.scrollIntoView()
              }, 30);
              
               
           }
       },
       props: ['idioma']
    }
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";
.modal{
    display: flex;
    align-items: left;
    justify-content: left;
    text-align: left;
    padding-top: 80px;
    padding-left: 1rem;
    
   

}
.flex{
    height: 70vh;
        display: flex;
    flex-direction: column;
    justify-content: space-between;
}

ul{
    margin:0;
    padding:0;
    list-style: none;
    color: $primaryWhite;
}

li{
    margin-bottom: 2vw;
    font-size: 5vh;
    line-height: 150%;
}
 a{
     color: $primaryWhite;
 }

 .socials{
    position: fixed;
    bottom: 1rem;
    left: 1rem;

    display: flex;
    gap:1rem;

 }

 @media (max-height: 400px) {
li{
    font-size: 1.5rem;
}




.flex{
    width: 80%;
    height: 100vh;
        display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.flecha{
    transform: rotate(-90deg);
}

 }

</style>