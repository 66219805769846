<template>
    <div v-show="!accepted" id="cookie-message">
    
      <p>Este sitio web utiliza <a href="/cookies">cookies</a>  para mejorar la experiencia del usuario. Por favor, acepta nuestras cookies para continuar.</p>
     <div class="buttons"> <button @click="acceptCookies" class="positivo">Aceptar cookies</button>
      <button @click="rejectCookies">Rechazar cookies</button></div>
    </div>
  </template>npm
  
  <script>
  export default {
    data() {
      return {
        accepted: false,
      };
    },
    mounted() {

  this.showHideCookieMessage();
  this.loadGoogleAnalytics();
    },
    methods: {
  

      setCookie(name, value, days) {
        var expires = "";
        if (days) {
          var date = new Date();
          date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
          expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
      },
      getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') c = c.substring(1, c.length);
          if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
      },
      eraseCookie(name) {
        document.cookie = name + '=; Max-Age=-99999999;';
      },
      showHideCookieMessage() {
        if (this.getCookie('cookies_accepted') === null) {
          this.accepted = false;
        } else {
          this.accepted = true;
        }
      },
      acceptCookies() {
        this.setCookie('cookies_accepted', 'true', 30);
        this.showHideCookieMessage();
        window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-ESN444BCHH');

      },
      rejectCookies() {
        this.setCookie('cookies_accepted', 'false',1);
        this.showHideCookieMessage();
      },
    },
  };
  </script>
  
 
  <style lang="scss" scoped >
  @import "../scss/variables.scss";
  #cookie-message {
    position: fixed;

    background-color: #f0f0f0;
    padding: 2rem;
    margin:1rem;
    font-size: 16px;
    box-shadow: 0px 0px 16px #dddddd;
    z-index: 13;
    top:0;
    left: 0;
    max-width: 600px;
    p{
      margin-top: 0;
      margin-bottom: 24px;
    }


    .buttons{
      display: flex;
     justify-content: center;
      gap: 2rem;

      button{
        padding:1rem;
        border: solid $primaryBlack 1px;
        font-size: 1rem;
        color: $primaryBlack;
        background-color:$primaryWhite ;
      }
      .positivo{
        background-color:$primaryBlack ;
        color:$primaryWhite;
      }
    }

    
  }

  @media (max-width: 440px) {
    #cookie-message {
      padding: 1rem;
    .buttons{

      gap: 1rem;

      button{
        padding:1rem 0;
        width: 100%;
      }
    }
  }
  }
  </style>
  