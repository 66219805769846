<template>
  <div>
    <div class="container">
      <div class="introducer">
        <div v-if="idioma == 'Español'">
          <span>Los principios que me guían</span>
        </div>
        <div v-if="idioma == 'Inglés'">
          <span>Principles that guide me</span>
        </div>
      </div>
      <!-- 1 -->
      <div class="module first">
        <div class="number">01</div>
        <div class="text bloqueInformacion">
          <div class="title" v-if="idioma == 'Español'">Mantenerlo simple</div>
          <div class="title" v-if="idioma == 'Inglés'">Keep it simple</div>
          <p v-if="idioma == 'Español'">
            Una pregunta constante en mi flujo de trabajo es: <br />
            ¿Qué aporta esto al usuario? Lo realmente difícil es hacerlo simple
            y sentar las bases del futuro escenario para escalar con sentido.
          </p>
          <p v-if="idioma == 'Inglés'">
            A constant question in my workflow is: <br />
            What does this bring to the user? Make it simple is the hardest
            thing and define the foundations of the future stage to scale with
            sense.
          </p>
        </div>
      </div>
      <!-- 2 -->
      <div class="module">
        <div class="number">02</div>
        <div class="text bloqueInformacion">
          <div class="title" v-if="idioma == 'Español'">Investigar siempre</div>
          <div class="title" v-if="idioma == 'Inglés'">Always research</div>
          <p v-if="idioma == 'Español'">
            Sentarse con clientes y stakeholders, comprender las necesidades
            entre negocio y personas. Hablemos de preocupaciones, anhelos y
            consigamos objetivos.
          </p>
          <p v-if="idioma == 'Inglés'">
            Meet up with clients and stakeholders, understand needs between
            business and people. Speak about fears, wishes and done let's get
            objectives.
          </p>
        </div>
      </div>
      <!-- 3 -->
      <div class="module">
        <div class="number">03</div>
        <div class="text bloqueInformacion">
          <div class="title" v-if="idioma == 'Español'">El ego fuera</div>
          <div class="title" v-if="idioma == 'Inglés'">Ego out</div>
          <p v-if="idioma == 'Español'">
            No diseño para mi, diseño para que las personas puedan llevar a cabo
            una tarea concreta. <br />Por eso es necesario escuchar a las personas,
            testear y reorientar de ser necesario.
          </p>
          <p v-if="idioma == 'Inglés'">
            I don't design for me, I design for people can do a concrete task.<br />
            For this reason is necessary listen people, test and redirect if is
            necessary.
          </p>
        </div>
      </div>
      <!-- 4 -->
      <div class="module last">
        <div class="number">04</div>
        <div class="text bloqueInformacion">
                    <div class="title" v-if="idioma == 'Español'">Aprendizaje constante</div>
          <div class="title" v-if="idioma == 'Inglés'">Constant learning</div>
          <p v-if="idioma=='Español'">
            Creo firmemente en la convergencia de las distintas formas de
            proyectar soluciones para un mismo problema. Un benchmarking
            constante cómo actitud de mejora.
          </p>
                    <p v-if="idioma=='Inglés'">
I believe in different ways of project solutions for a concrete problem. A constant benchmarking as improvement attitude.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Principles",
  props: ["idioma"],
};
</script>

<style lang="scss">
@import "../scss/variables.scss";
@import "../scss/projectStyles.scss";

.container {
  text-align: left;

  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: 0rem auto;
  margin-top: 2rem;

  .module {
    display: flex;
    justify-content: space-between;
    align-items: left;
    border-top: 1px rgb(212, 212, 212) solid;
    flex-direction: column;

    padding: 4rem 0;

    &.first {
      border-top: none;
    }
    &.last {
      border-bottom: none;
    }
  }

  .number {
    font-size: 6rem;
    color: #ababab;
    min-width: 120px;
    
  }

  .text {
    max-width: 40rem;
    text-align: left;
    .title {
      font-size: 3rem;
      margin-bottom: 1rem;
    }
  }
}

@media (min-width: 820px) {
  .container {
    .module {
      flex-direction: row;
      align-items: left;
      padding: 8rem;
      gap:2rem;

      &.first {
        border-top: none;
      }
      &.last {
        border-bottom: none;
      }
    }
  }
  
}
</style>

