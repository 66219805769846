<template>
    <div>
            <div id="back-button" @click="navegarAlProyecto">
                <---

                   
            </div>
             <!-- <router-link to="/" ><---</router-link> -->
    </div>
</template>

<script>

    export default {
        name:'BackButton',
props:['Activartransicion'],
        methods:{
                navegarAlProyecto:function(){
                    console.log('boton back pulsado')
     this.Activartransicion()
     setTimeout(() => {
       this.$router.push('/#')
     }, 600);
     
     
   }

        }
    }
</script>

<style lang="scss">


@import "../scss/variables.scss";

#back-button {
    cursor: pointer;
  mix-blend-mode: difference;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8;
  padding: 30px;
  color:$primaryWhite;
  a{
      color:$primaryWhite;
      text-decoration: none;
      transition-duration: 1s;

     
  }

   a:hover{
          font-weight: 800;
          transition-duration: 1s;
      }
}


</style>