<template>
  <div class="envelope">
    <div class="Text-big" v-if="idioma == 'Español'">
      Eleva tu proyecto al siguiente nivel
    </div>
    <div class="Text-big" v-if="idioma == 'Inglés'">
      Take your project to the next level
    </div>
    <img
      src="../assets/1.png"
      alt="Es una imagen de un sobre iridiscente"
    />
  </div>
</template>

<script>
export default {
  name: "Envelope",
  props: ["idioma"],
};
</script>

<style lang="scss">
@import "../scss/variables.scss";

.envelope {
  position: relative;
  padding-top: 4rem;
  padding-bottom: 4rem;
  margin-left: 0px;
  

  .Text-big {
    position: relative;
    font-size: 12vw;
    line-height: 110%;
    z-index: 2;
    margin-left: -0.5vw;
    color: $primaryBlack;
   
  }

  img {
    width: 50vw;
    position: absolute;
    right: -24vw;
    z-index: 1;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }

@media (min-width: 100px) and (max-width: 800px) {
    padding-top: 0rem;
  padding-bottom: 0rem;


    .Text-big {
      line-height: 120%;
    }

    img {
      
    
  }
}
}

@media (min-width: 1790px) {
.envelope{  
  .Text-big {
    padding-top: 8rem;
  padding-bottom: 8rem;
    font-size: 8vw;


   
  }}


}

</style>