<template>
  <div class="overlay">
  </div>
</template>

<script>
export default {
name:'Transicion',
}


</script>

<style>
.overlay{
    position: fixed;
    top:0;
    width: 100vw;
    height: 0vh;
    background: #333;
    z-index: 5000;
    animation: avioncito 4s ease-in-out; 
    

    
}
 @keyframes avioncito{
    
10%{
        height:100vh;

    };



100%{
        height:0vh;
    
        
    };


} 





 

</style>