<template>
  <div>
    <BackButton v-bind:Activartransicion="$attrs.Activartransicion"  @emisario="ToggleTransition" />
    
<div id="cookies" class="cookies containerProyect">
  <div v-if="this.idioma=='Español'" >
    <h1>Qué son las cookies y cómo eliminarlas</h1>
	<p>Las cookies son pequeños archivos de texto que se descargan en tu dispositivo cuando navegas por sitios web. Se utilizan para personalizar tu experiencia de navegación y ofrecerte anuncios y contenidos relevantes.</p>
	<h4>Existen diferentes tipos de cookies:</h4>
	<ul>
		<li>Cookies de sesión: se utilizan para gestionar tu sesión de navegación y se eliminan automáticamente cuando cierras el navegador.</li>
		<li>Cookies persistentes: permanecen en tu dispositivo incluso después de cerrar el navegador y se utilizan para recordar tus preferencias y mejorar tu experiencia de navegación.</li>
		<li>Cookies de terceros: son gestionadas por empresas distintas al propietario del sitio web que estás visitando y se utilizan para recopilar información sobre tus intereses y ofrecerte publicidad personalizada.</li>
		<li>Cookies de Google Analytics: se utilizan para recopilar información sobre el uso del sitio web y para generar informes de estadísticas de tráfico.</li>
	</ul>
	<h4>Si deseas eliminar las cookies en los principales navegadores, sigue los siguientes pasos:</h4>
	<ul>
		<li>En Google Chrome: ve a Configuración &gt; Privacidad y seguridad &gt; Borrar datos de navegación. Selecciona el intervalo de tiempo y las opciones de datos que deseas eliminar y haz clic en "Borrar datos".</li>
		<li>En Mozilla Firefox: ve a Opciones &gt; Privacidad y seguridad &gt; Cookies y datos del sitio web &gt; Administrar datos. Selecciona el sitio web y las cookies que deseas eliminar y haz clic en "Eliminar seleccionados".</li>
		<li>En Safari: ve a Preferencias &gt; Privacidad &gt; Administrar datos de sitio web. Selecciona las cookies que deseas eliminar y haz clic en "Eliminar" o "Eliminar todos".</li>
		<li>En Microsoft Edge: ve a Configuración y más &gt; Configuración &gt; Privacidad, búsqueda y servicios &gt; Borrar datos de navegación. Selecciona el intervalo de tiempo y las opciones de datos que deseas eliminar y haz clic en "Borrar ahora".</li>
	</ul>
  
  <h4>En este site utilizamos las siguientes cookies:</h4>
  <li>_GA de Google Analytics</li>
    <p style="margin-bottom: 0; margin-top: 2rem;">Si deseas eliminar todas las cookies, haz click en el siguiente botón:</p>
    <button  @click="deleteAllCookies">Eliminar todas las cookies</button>
  </div>
         

         <div v-if="this.idioma=='Inglés'" >

          <h1>What are cookies and how to delete them</h1>
	<p>Cookies are small text files that are downloaded onto your device when you browse websites. They are used to personalize your browsing experience and offer you relevant ads and content.</p>
	<h4>There are different types of cookies:</h4>
	<ul>
		<li>Session cookies: used to manage your browsing session and are automatically deleted when you close the browser.</li>
		<li>Persistent cookies: remain on your device even after you close the browser and are used to remember your preferences and improve your browsing experience.</li>
		<li>Third-party cookies: managed by companies other than the owner of the website you are visiting and used to collect information about your interests and offer you personalized advertising.</li>
		<li>Google Analytics cookies: used to collect information about website usage and generate traffic statistics reports.</li>
	</ul>
	<h4>If you want to delete cookies in major browsers, follow these steps:</h4>
	<ul>
		<li>In Google Chrome: go to Settings &gt; Privacy and security &gt; Clear browsing data. Select the time range and data options you want to delete and click "Clear data".</li>
		<li>In Mozilla Firefox: go to Options &gt; Privacy and security &gt; Cookies and site data &gt; Manage data. Select the website and cookies you want to delete and click "Remove Selected".</li>
		<li>In Safari: go to Preferences &gt; Privacy &gt; Manage Website Data. Select the cookies you want to delete and click "Remove" or "Remove All".</li>
		<li>In Microsoft Edge: go to Settings and more &gt; Settings &gt; Privacy, search, and services &gt; Clear browsing data. Select the time range and data options you want to delete and click "Clear now".</li>
	</ul>
  <h4>We use the following cookies on this site:</h4>
  <li>_GA from Google Analytics</li>
    <p style="margin-bottom: 0; margin-top: 2rem;">If you want to delete all cookies, click the following button:</p>
    <button  @click="deleteAllCookies">Delete all cookies</button>
         </div>
        </div>

      </div>
</template>

<script>
import BackButton from "@/components/BackButton";
import Contact from "@/components/Contact.vue"
export default {
    data() {
    return {
      props: ['idioma'],
      cookies: {
        _ga_ESN444BCHH: true,
        _ga: true
      }
    };
  },
  methods: {
    deleteAllCookies() {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
      alert("Todas las cookies han sido eliminadas");
    }
  },

  props: ['idioma'],
  components: {
    BackButton,
    Contact
  },created(){
    window.scroll(0,0);
    document.title = 'Cookies - Victor Fernández'
     }
};
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";
@import "../scss/projectStyles.scss";

.cookies{
    max-width: 800px;
    margin:0 auto;
    padding-bottom: 6rem;
}
li{
    margin:1rem 0;
}
ul{
  padding-bottom:1rem;
}
p{
    padding:1rem 0;
}
ul{
  padding-inline-start: 24px;

}
button{
  padding:1rem;
        border: solid $primaryBlack 1px;
        font-size: 1rem;
        color: $primaryBlack;
        background-color:$primaryWhite ;
}
h4{
margin-top: 2rem;
}
</style>