<!-- En un archivo llamado OpinionCarousel.vue -->
<template>
  
    <div class="opinion-carousel">
      <div class="carousel-wrapper" :style="{ transform: `translateX(${-currentIndex * itemWidth}px)` }">
        <div v-for="(opinion, index) in opinions" :key="index" class="opinion-card">
          <div class="information">
          <div class="data text-2">
          <img :src="opinion.image" alt="Avatar" class="avatar">
          <div class="people">
          <p class="title">{{ opinion.title }}</p>
          <p class="subtitle bold text-1">{{ opinion.subtitle }}</p>
        </div>
        </div>
          <p class="testimonial text-3">{{ opinion.testimonial }}</p>
        </div>
          <div class="stars"><svg width="132" height="20" viewBox="0 0 132 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 0L12.6568 6.34324L19.5106 6.90983L14.2988 11.3968L15.8779 18.0902L10 14.52L4.12215 18.0902L5.70122 11.3968L0.489435 6.90983L7.34321 6.34324L10 0Z" fill="#505050"/>
<path d="M38 0L40.6568 6.34324L47.5106 6.90983L42.2988 11.3968L43.8779 18.0902L38 14.52L32.1221 18.0902L33.7012 11.3968L28.4894 6.90983L35.3432 6.34324L38 0Z" fill="#505050"/>
<path d="M66 0L68.6568 6.34324L75.5106 6.90983L70.2988 11.3968L71.8779 18.0902L66 14.52L60.1221 18.0902L61.7012 11.3968L56.4894 6.90983L63.3432 6.34324L66 0Z" fill="#505050"/>
<path d="M94 0L96.6568 6.34324L103.511 6.90983L98.2988 11.3968L99.8779 18.0902L94 14.52L88.1221 18.0902L89.7012 11.3968L84.4894 6.90983L91.3432 6.34324L94 0Z" fill="#505050"/>
<path d="M122 0L124.657 6.34324L131.511 6.90983L126.299 11.3968L127.878 18.0902L122 14.52L116.122 18.0902L117.701 11.3968L112.489 6.90983L119.343 6.34324L122 0Z" fill="#505050"/>
</svg>
</div>
        </div>
      </div>
      <div class="controls">
        <button @click="prevOpinion" :disabled="currentIndex === 0"><svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.46967 5.46966C0.176777 5.76256 0.176777 6.23743 0.46967 6.53032L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 5.99999L6.3033 1.75735C6.59619 1.46446 6.59619 0.989586 6.3033 0.696692C6.01041 0.403799 5.53553 0.403799 5.24264 0.696692L0.46967 5.46966ZM17 5.25L1 5.24999L1 6.74999L17 6.75L17 5.25Z" fill="white"/>
</svg>


</button>
        <button @click="nextOpinion" :disabled="currentIndex >= opinions.length - itemsPerSlide"><svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.5303 6.53032C16.8232 6.23743 16.8232 5.76256 16.5303 5.46966L11.7574 0.69669C11.4645 0.403797 10.9896 0.403797 10.6967 0.69669C10.4038 0.989583 10.4038 1.46446 10.6967 1.75735L14.9393 5.99999L10.6967 10.2426C10.4038 10.5355 10.4038 11.0104 10.6967 11.3033C10.9896 11.5962 11.4645 11.5962 11.7574 11.3033L16.5303 6.53032ZM-1.96701e-07 6.74999L16 6.74999L16 5.24999L1.96701e-07 5.24999L-1.96701e-07 6.74999Z" fill="white"/>
</svg>

</button>
      </div>
    </div>
  </template>
  
  <script>

    export default {
  name: "OpinionCarousel",
  props: ["idioma"],

    data() {
      return {
        opinions: [
        {
            image: require('../assets/yure.jpg'),
            title: 'Yure Socas',
            subtitle: 'Product designer',
            testimonial: 'He trabajado con Víctor en dos empresas distintas y en ambas experiencias ha destacado por su capacidad empática y resolutiva. Sabe adaptarse a las necesidades y circunstancias del momento buscando el máximo equilibrio entre las necesidades del los usuarios y negocio. Es un gran líder y compañero.',
          },
        {
            image: require('../assets/willy.jpg'),
            title: 'Guillermo Ceballos',
            subtitle: 'Co-Founder & CTO at Zazume',
            testimonial: 'Recomendar a Víctor es fácil. Su capacidad para integrarse en equipos, empatizar con los usuarios y entregar buenos resultados hacen de él un perfil de alto valor.',
          },
          {
            image: require('../assets/patri.jpg'),
            title: 'Patricia Borrallo',
            subtitle: 'Freelance Product Designer | Decathlon',
            testimonial: 'Trabajé con Víctor en varios proyectos y su experiencia liderando equipos y mantener la simplicidad es insuperable. Un profesional de primera categoría.',
          },
         
          {
            image: require('../assets/alberto.jpg'),
            title: 'Alberto Mora',
            subtitle: 'Co-Founder at SBR Music',
            testimonial: 'Víctor es el diseñador que todos desean tener en su equipo. Su integración perfecta, atención a las necesidades del usuario y su entrega de resultados lo convierten en un profesional excepcional.',
          },
  
          // Agrega más opiniones según sea necesario
        ],
        currentIndex: 0,
        itemsPerSlide: 3,
        itemWidth: 0,
        isMobile: false,
      };
    },
    mounted() {
      this.checkScreenWidth();
      window.addEventListener('resize', this.checkScreenWidth);
      this.itemWidth = this.$el.offsetWidth / this.itemsPerSlide;
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.checkScreenWidth);
    },
    methods: {
      checkScreenWidth() {
        this.isMobile = window.innerWidth <= 768; // Ajusta según tus necesidades de breakpoint
        if (this.isMobile) {
          this.itemsPerSlide = 1;
        } else {
          this.itemsPerSlide = 2;
        }
        this.itemWidth = this.$el.offsetWidth / this.itemsPerSlide;
      },
      nextOpinion() {
        if (this.currentIndex < this.opinions.length - 1) {
          this.currentIndex++;
        }
      },
      prevOpinion() {
        if (this.currentIndex > 0) {
          this.currentIndex--;
        }
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
@import "../scss/variables.scss";
@import "../scss/projectStyles.scss";


  .data{
    display: flex;
    flex-direction: row;
    font-size: 1rem;
    column-gap: 1rem;
    align-items: center;

    p{
      margin:0;
    }
  }

  .people{
    display: flex;
    flex-direction: column;
    row-gap:.2rem;
    
    .subtitle{
      color: $grey1;
    }
  }

  .testimonial{
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .opinion-carousel {
    overflow: hidden;
    margin-top: 4rem;
 
  }
  
  .carousel-wrapper {
    display: flex;
    transition: transform 0.3s ease-in-out;
    column-gap: 2rem;
  }
  
  .opinion-card {
  flex: 0 0 calc(90% / 2);
  box-sizing: border-box;
  text-align: left;
  padding: 24px;
  border: 1px solid $grey0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  }
  
  .avatar {
    border-radius: 50%;
    width: 64px;
    height: 64px;
    object-fit: cover;
    background-color:$grey1;
  }
  
  .controls {
    display: flex;
    justify-content: center;
    column-gap: 1rem;
    padding:3rem;
    
  }
  
  button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border-width: 0px;
    background-color:  $primaryBlack;

  }

  button:hover{
    background-color:$primaryBlackHover ;
  }

  button:disabled{
    background-color: $grey0;
  }
  

  /* Estilos adicionales para dispositivos móviles */
  @media (max-width: 768px) {
    .opinion-card {
      flex: 0 0 100%;
    }
    .carousel-wrapper {
    column-gap: 0rem;
  }
  }
  </style>
  