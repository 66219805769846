

<template>
  <div class="rates">
    <h1>Precios</h1>
    <div class="mode">
      <span class="modalidad" v-bind:class="{ activo: !toggleador }">Hora</span>
      <label class="switch">
        <input @click="changeMode" type="checkbox" />
        <span class="slider round"></span>
      </label>
      <span class="modalidad" v-bind:class="{ activo: toggleador }"
        >Jornada</span
      >
    </div>

    <div class="rango">
      <div class="rangeContainer">
        <input
          v-model="horas"
          type="range"
          name=""
          id=""
          max="120"
          min="10"
          step="5"
          class="swatch"
        />
      </div>
      <!-- <input v-model="horas" type="field" name="" id="" /> -->
      <div class="horas">{{ horas }} horas</div> <br />
      <!-- <p>{{ Math.round((45 - porcentaje * horas) * horas * 100) / 100 }}</p>
      <p>{{ 45 - porcentaje * horas }}</p> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      toggleador: false,
      horas: 10,
      porcentaje: 5 / 120,
    };
  },

  methods: {
    changeMode: function () {
      this.toggleador = !this.toggleador;
      console.log(this.toggleador);
    },
  },
};
</script>


<style lang="scss">
.rates {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1{
  font-weight: 500;
  font-size: 46px;
  margin: 64px 0;
}
.horas{
  font-size: 2rem;
  margin: 64px 0;
}
.mode {
  display: flex;
  align-items: center;
  margin-bottom:64px ;
  .modalidad {
    color: rgb(170, 170, 170);
    font-size: 2rem;
  }

  .activo {
    color: #333;
  }

  /* The switch - the box around the slider */
  .switch {
    margin: 1.5rem;
    position: relative;
    display: inline-block;
    width: 85px;
    height: 34px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #333;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #333;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(51px);
    -ms-transform: translateX(51px);
    transform: translateX(51px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.rangeContainer {
  width: 600px;

  .swatch {
    -webkit-appearance: none;
    height: 8px;
    width: 100%;
    border-radius: 8px;
    border: none;
    outline: none;
    background: rgb(209, 209, 209);
  }

  .swatch::-moz-range-progress{
    background-color: #333;
       height: 8px;
       border-radius: 8px;
  }
  .swatch::-moz-range-thumb{
    position: relative;
    width: 16px;
    height: 16px;
    border-radius: 80px;
    background-color: #333;
    border: solid 5px #f0f0f0;
    box-shadow: 0px 0px 6.95652px rgba(0, 0, 0, 0.25);
  }
}
</style>>
