<template>
    
      <div class="programar">
        
      <div class="data"> <a href="https://cal.com/victor-fernandez/15min"><span v-if="this.idioma=='Español'" >Reservar llamada</span> <span v-if="this.idioma=='Inglés'" >Book a call</span> <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 1.5C18 0.947716 17.5523 0.5 17 0.5L8 0.500001C7.44771 0.5 7 0.947716 7 1.5C7 2.05229 7.44772 2.5 8 2.5L16 2.5L16 10.5C16 11.0523 16.4477 11.5 17 11.5C17.5523 11.5 18 11.0523 18 10.5L18 1.5ZM1.70711 18.2071L17.7071 2.20711L16.2929 0.792894L0.292893 16.7929L1.70711 18.2071Z" fill="#fff"/>
</svg>
</a> 

</div>

</div>

</template>

<script>
export default {
    name:'Programar',
    props:['idioma'],
}
</script>

<style lang="scss">
.programar{
  
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  
  z-index: 9;
  
  .data{
    

    a{
      background-color: #333;
      padding:1rem 2rem;
      text-decoration: none;
      font-size: 1rem;
      color: white;
      display: flex;
    align-items: center;



      }
    a:hover{
      background-color: rgb(78, 78, 78);
    }
      svg{
              margin-left: .5rem;
              scale: .7;
              
      }

     
  }
   a:hover{
        
      }

}

@media (max-width: 600px) {
.programar{
position: fixed;

bottom: 1rem;
left:1rem;
right:1rem;
.data{
 
 a{
   font-size: 1.1rem;
   
   justify-content: center;
 }
}


}

}
</style>