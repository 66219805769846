<template>
    <div class="faq" @click="toggleOpen" :class="{ open: isOpen }">
      <p class="question" >{{ question }}</p>
      <div class="arrow-container">
        <div class="arrow"><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.364 8.29289C15.7545 7.90237 16.3877 7.90237 16.7782 8.29289L23.1421 14.6569L21.7279 16.0711L17.0711 11.4142V25H15.0711V11.4142L10.4142 16.0711L9 14.6569L15.364 8.29289Z" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30ZM16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="#77777"/>
</svg>



</div>
      </div>
      <div class="answer">{{ answer }}</div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      question: {
        type: String,
        required: true,
      },
      answer: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        isOpen: false,
      };
    },
    methods: {
      toggleOpen() {
        this.isOpen = !this.isOpen;
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .faq {
  
    border: 1px solid #d4d4d4;
    border-radius: 0px;

    position: relative;
    transition: all 0.2s ease-in-out;
  
  }
  .faq p {
    margin: 0;
    padding-left: 2rem;
  }
  .faq .question {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 32px;
    width: 80%;
    padding-top: 20px;
    padding-bottom: 1rem;
 
    
  }
  .faq .answer {
    display: none;
    font-size: 1.2rem;
    line-height: 140%;
    padding-top: 0;
    color: #555;
    max-width: 800px;
  
  }
  .arrow-container {
    position: absolute;
    right: 24px;
    top:24px
   
  }
  .faq .arrow  {
    transform: rotate(180deg);
    transition: all 0.3s ease-in-out;
    height: 32px;
    
    
    svg{
      fill:#6b6b6b;
    }
    

  }
  .faq.open {

    border:solid 1.5px #d4d4d4;
    
  }
  .faq.open .question {
   
  }
  .faq.open .arrow  {
    
    transform: rotate(0deg);
    svg{
      fill:#333
    }
    
    
  }
  .faq.open .answer {
    display: block;
   
  }

 

    .faq {
  border:solid 0px;
  border-top: 1px solid #d4d4d4;

  border-radius: 0px;
padding-bottom: 3rem;


}

.faq.open {
  border:solid 0px;
  border-top: 1px solid #d4d4d4;
 


}
    .faq p {
    margin: 0;
    padding-left: 0rem;
  }

  @media (max-width: 600px) {

   .faq .question{
      font-size: 24px;
    }
  }


  </style>