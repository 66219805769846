<template>
  <div class="proyecto">
    <BackButton
      v-bind:Activartransicion="$attrs.Activartransicion"
      @emisario="ToggleTransition"
    />
    <div class="containerProyect">
      <div class="projectName">
        <h1>Decathlon</h1>
      </div>
      <div class="subName">
        <h2 v-if="idioma == 'Español'">El deporte como punto de partida</h2>
        <h2 v-if="idioma == 'Inglés'">Sports as start point</h2>
      </div>

      <div class="bloqueInformacion">
        <div class="fullImg">
          <img
            src="../assets/decathlon/decathlon-oficinas.jpg"
            alt="En la imagen aparece varias personas jóvenes en un estudio de grabación musical"
          />
        </div>
        <div class="introducer">
          <span v-if="idioma == 'Español'">Planteamiento</span>
          <span v-if="idioma == 'Inglés'">Approach</span>
        </div>
        <div class="block">
          <div class="title">
            <h3 v-if="idioma == 'Español'">Sobre el proyecto</h3>
            <h3 v-if="idioma == 'Inglés'">About the project</h3>
          </div>
          <p v-if="idioma == 'Español'">
            Integración como Lead Product Designer en una de las verticales con
            mayor impacto en la cuenta de resultados de Decathlon.es
            <br />
            <br />
            Durante un año y medio realicé distintos proyectos que impactaban
            directamente en la conversión y usabilidad del sitio web, además de
            liderar la vertical y ayudar al equipo en su trayectoria
            profesional.
          </p>

          <p v-if="idioma == 'Inglés'">
            Integration as Lead Product Designer in one of the verticals with
            the highest impact on Decathlon.es's financial results.
            <br />
            <br />
            During a year and a half, I undertook various projects directly
            impacting website conversion and usability, in addition to leading
            the vertical and assisting the team in their professional journey.
            <br />
            <br />
          </p>
        </div>
        <div class="block">
          <div class="title">
            <h3 v-if="idioma == 'Español'">El equipo</h3>
            <h3 v-if="idioma == 'Inglés'">The team</h3>
          </div>
          <p v-if="idioma == 'Español'">
            Integración total en el equipo, participación en los distintos
            rituales y sprints como si fuese uno más, llegando a hacer carrera
            como externo. - de hecho para mi el éxito de la integración se mide
            justo así. -
          </p>
          <p v-if="idioma == 'Inglés'">
            Total integration in the team, participation in the different rituals and sprints like one more, doing career as external - for me, the integration success can be measured in this way.-
          </p>
        </div>
        <div class="block">
          <div class="title">
            <h3 v-if="idioma == 'Español'">¿Desafíos más importantes?</h3>
            <h3 v-if="idioma == 'Inglés'">Most important challenge</h3>
          </div>
          <p v-if="idioma == 'Español'">
            Personalmente creo que pasar de ser un perfil 100% productor a uno
            que combinaba la parte de estrategia y planificación. <br /><br />
            Pero quizás el desafío más importante fue soportar la presión y la
            responsabilidad que supone tomar decisiones basadas en la validación
            de hipótesis. Cualquier cambio, un componente que no funcionase de
            forma correcta, podía suponer una pérdida millonaria diaria. También
            lo contrario, areglar algo que falla, o descubrir algo que
            funcionase, podía suponía una ganancia millonaría.
          </p>
          <p v-if="idioma == 'Inglés'">
            Personally, I think that going from being a 100% producer profile to one
            which combined the strategy and planning part. <br /><br />
            But perhaps the most important challenge was withstanding the pressure and
            responsibility of making decisions based on validation
            of hypotheses. Any change, a component that does not work
            correctly, it could mean a daily loss of millions. Also
            Otherwise, fix something that is wrong, or discover something that
            If it worked, it could mean a million-dollar profit.
          </p>
        </div>
        <div class="fullImg">
          <img
            src="../assets/decathlon/Mockup-decathlon.jpg"
            alt="En la imagen aparece varias personas jóvenes en un estudio de grabación musical"
          />
        </div>
        <div class="introducer">
          <span v-if="idioma == 'Español'">¡Vamos a ello!</span>
          <span v-if="idioma == 'Inglés'">Here we go!</span>
        </div>
        <div class="block">
          <div class="title">
            <h3 v-if="idioma == 'Español'">Sobre mi rol en el proyecto</h3>
            <h3 v-if="idioma == 'Inglés'">About my rol in the project</h3>
          </div>
          <ul v-if="idioma == 'Español'">
            <li>
              <p>
                Participación en el roadmap junto a la Product Owner y distintos
                stakeholders, planificación y ejecución de los sprints,
                facilitando la entrega del equipo de diseño de la squad, así
                como el handoff con el resto de roles que participaban en el
                sprint.
              </p>
            </li>
            <li>
              <p>
                Coordinación con Decathlon internacional para la validación de
                hipótesis y su implementación a nivel local.
              </p>
            </li>

            <li>
              <p>Diseño y planificación de estudios y tests con usuarios.</p>
            </li>

            <li>
              <p>
                Evaluación y mejora de los distintos puntos críticos en el
                journey.
              </p>
            </li>
            <li>
              <p>
                Participación como contributor en el sistema de diseño
                internacional Vitamin, sugiriendo desde España distintas
                variaciones.
              </p>
            </li>
          </ul>

          <ul v-if="idioma == 'Inglés'">
            <li>
              <p>
                Participation in the roadmap together with the Product Owner and different
                stakeholders, planning and execution of sprints,
                facilitating the delivery of the squad design team, as well
                like the handoff with the rest of the roles that participated in the
                sprint.
              </p>
            </li>
            <li>
              <p>
                Coordination with Decathlon international for the validation of
                hypothesis and its implementation at the local level.
              </p>
            </li>

            <li>
              <p>Design and planning of studies and tests with users.</p>
            </li>

            <li>
              <p>
                Evaluation and improvement of the different critical points in the
                journey.
              </p>
            </li>
            <li>
              <p>
                Participation as a contributor in the design system
                International Vitamin, suggesting from Spain different
                variations.
              </p>
            </li>
          </ul>
        </div>

        <!-- <div class="block">
          <div class="title">
            <h3>Testing</h3>
          </div>
          <p v-if="idioma == 'Español'">
            Se testean las soluciones con los distintos usuarios, planificando
            tareas concretas y cruciales para los objetivos planteados en un
            entorno real. <br />
            <br />

            Gracias al método de observación, se observaron fallos de
            arquitectura de información que pudieron corregirse en fases
            tempranas, ahorrando así costes futuros.
          </p>

          <p v-if="idioma == 'Inglés'">
            We tested the solutions with different users, we prepared concrete
            tasks and critical to complete our targets in a real environment.
            <br />
            <br />

            Thanks to the method of observation, we observed many fails at
            information architecture they could be fixed at early phases, saving
            future costs.
          </p>
        </div>

        <div class="fullImg">
          <img
            src="../assets/wow/testing.jpg"
            alt="Aparecen dos personas realizando un test de producto digital, en este caso una persona sujeta un teléfono mientras la otra observa como lo usa"
          />
        </div>
        <div class="introducer">
          <span v-if="idioma == 'Español'">Funciones destacadas</span>
          <span v-if="idioma == 'Inglés'">Some features</span>
        </div>
        <div class="block">
          <div class="title">
            <h3>Marketplace ++</h3>
          </div>
          <div>
            <p v-if="idioma == 'Español'">
              Los productores de musica pueden publicar sus instrumentales y
              comercializarlas libremente en Wow, se proyecta un ranking a medio
              plazo para motivar la competitividad.
            </p>

            <p v-if="idioma == 'Inglés'">
              Music producers may publish his instrumentals and sell these
              freely in WOW, we are thinking about a ranked to improve the
              motivation and competitivity
            </p>
            <div class="fullImg">
              <img
                src="../assets/wow/marketplace.png"
                alt="Es una imagen del proyecto WOW, en ella se muestra el marketplace y las funciones que podrán utilizar los usuarios."
              />
            </div>
          </div>
        </div>
        <div class="title extrapadding">
          <h3 v-if="idioma == 'Español'">Estudio</h3>
          <h3 v-if="idioma == 'Inglés'">Studio</h3>
        </div>
        <div class="block2">
          <div>
            <h4>Beats</h4>
            <p v-if="idioma == 'Español'">
              En esta funcionalidad el usuario puede escuchar sus beats
              favoritos y comenzar a realizar una grabación.
            </p>
            <p v-if="idioma == 'Inglés'">
              On this function the user will listen his favourites beats and
              could start a rec.
            </p>
            <div class="fullImg">
              <img
                src="../assets/wow/beats.png"
                alt="En esta imagen aparece una visualización del listado de beats que los usuarios podrán escuchar"
              />
            </div>
          </div>
          <div>
            <h4 v-if="idioma == 'Español'">Letras</h4>
            <h4 v-if="idioma == 'Inglés'">Lyrics</h4>
            <p v-if="idioma == 'Español'">
              El usuario puede escribir sus canciones y leerlas mientras graba
              una prueba de audio.
            </p>
            <p v-if="idioma == 'Inglés'">
              The user could write his songs and read these while is recording a
              demo.
            </p>
            <div class="fullImg">
              <img
                src="../assets/wow/letras.png"
                alt="Esta imagen ilustra el editor de texto de la aplicación, los usuarios podrán utilizarla para escribir sus canciones"
              />
            </div>
          </div>
          <div>
            <h4 v-if="idioma == 'Español'">Grabar</h4>
            <h4 v-if="idioma == 'Inglés'">Rec</h4>
            <p v-if="idioma == 'Español'">
              El usuario puede utilizar un beat para hacer pruebas de grabación,
              en esta grabación podrá leer su letra.
            </p>
            <p v-if="idioma == 'Inglés'">
              User could use a beat to make a demo, in this demo the user will
              read his lyric.
            </p>
            <div class="fullImg">
              <img
                src="../assets/wow/grabar.png"
                alt="En esta imagen se muestra la interfaz que utilizarán los usuarios para hacer sus pruebas de grabación musical."
              />
            </div>
          </div>
          <div>
            <h4 v-if="idioma == 'Español'">Reproductor</h4>
            <h4 v-if="idioma == 'Inglés'">Music player</h4>
            <p v-if="idioma == 'Español'">
              Reproductor basado en los patrones conocidos por los usuarios,
              añadiendo algún gesto o menú contextual.
            </p>
            <p v-if="idioma == 'Inglés'">
              Music player based in patterns known by users, adding some gesture
              and contextual menu.
            </p>
            <div class="fullImg">
              <img
                src="../assets/wow/reproductor.png"
                alt="Los usuarios podrán reproducir instrumentales y escuchar sus canciones, esta imagen muestra el reproductor."
              />
            </div>
          </div>
        </div>
        <div class="block">
          <div class="title">
            <h3 v-if="idioma == 'Español'">¿Qué aprendí en este proyecto?</h3>
            <h3 v-if="idioma == 'Inglés'">Challenges and learnings</h3>
          </div>
          <p v-if="idioma == 'Español'">
            Al tratarse de un proyecto End to end con un equipo pequeño, me
            sirvió para estar en todas las fases del producto, investigando y
            aterrizando ideas a prototipos de alta calidad.
          </p>
          <p v-if="idioma == 'Inglés'">
            This was an end to end project wit a little team, I was in all
            product's phases, researching and landing ideas to Hi-Fi prototypes.
          </p>
        </div>
      </div>
      <div id="contact">
        <Contact v-bind:idioma="this.idioma"></Contact>
      </div> -->
      </div>
      <!-- Fin proyect -->

      <div id="contact">
        <div v-if="idioma == 'Español'" class="contactNew">
          <ContactNew
            v-bind:idioma="this.idioma"
            text="Lo que necesitas, a un clic."
          />
        </div>

        <div v-if="idioma == 'Inglés'" class="contactNew">
          <ContactNew
            v-bind:idioma="this.idioma"
            text="Everything you need is one click away."
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/BackButton";
import Contact from "@/components/Contact.vue";
import ContactNew from "@/components/ContactNew.vue";
export default {
  props: ["idioma"],
  components: {
    BackButton,
    ContactNew,
  },
  created() {
    window.scroll(0, 0);
    document.title = "Decathlon - Victor Fernández";
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";
@import "../scss/projectStyles.scss";
</style>
