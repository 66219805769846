<template>
  <div>

  <div v-if="idioma == 'Español' ">
      <div><h2>
        ¡Hola! Soy Víctor Fernández, Diseñador de
        <span>productos y servicios digitales,</span> he trabajado durante los
        últimos años investigando y diseñando productos digitales en campos
        relacionados con
        <span>funcionalidad, experiencia de usuario, interfaz de usuario,
          arquitectura de la información e interacción.
        </span> <br> <br>

        Participo en la organización de <a target="_blank" href="https://twitter.com/padawanes">Padawanes</a>  y hablo de movidas relacionadas con diseño y tecnología en los podcasts <a href="https://twitter.com/iterandopodcast">Iterando</a> y <a href="https://anchor.fm/planes">Planes</a>.
      </h2></div>
  </div>

  <div  v-if="idioma == 'Inglés' ">
      <div><h2>
        Hello! I'm Víctor Fernández,
        <span>a Product </span> Designer, I've worked last years researching and designing digital products in related fields as 
        
          <span>funcionality, user experience, user interface, information archiquecture and interaction.</span> 
          <br> <br>

        I'm part of the organization of  <a target="_blank" href="https://twitter.com/padawanes">Padawanes</a>  and I talk about things related to design and tech in the podcasts <a href="https://twitter.com/iterandopodcast">Iterando</a> and <a href="https://anchor.fm/planes">Planes</a>.
      
        
      </h2></div>
  </div>

  


  </div>

</template>

<script>
export default {
  name: "Resume",
  props: ['idioma'],
  data: function() {
    return {
      resumenEspañol: ``,
      resumeEnglish:``,
      spanish:false,

    };
  },
  watch:{

  }
};
</script>

<style lang="scss">

a{
  color: #333;

}
a:hover{
  color: #30c881;
}

  h2 {
    text-align: left;
    font-size: 5.4vw;
    text-align: left;
    font-weight: 300;
    line-height: 150%;
    span {
      font-weight: 500;
    }
  }


@media (min-width: 500px) {

    
    h2 {
      text-align: left;
      font-size: 2rem;
      text-align: left;
      font-weight: 300;
      line-height: 150%;
      span {
        font-weight: 500;
      }
    }
}

@media (min-width: 1024px) {

    h2 {
    
      text-align: left;
      font-size: 2.5rem;
      text-align: left;
      font-weight: 300;
      line-height: 160%;
      span {
        font-weight: 500;
      }
    }
}
</style>
