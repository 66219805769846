<template>

  <div class="proyecto">
    <BackButton v-bind:Activartransicion="$attrs.Activartransicion"  @emisario="ToggleTransition" />
    <div class="containerProyect">
      <div class="projectName">
        <h1>Wow</h1>
      </div>
      <div class="subName">
        <h2 v-if="idioma=='Español'">Un estudio de grabación en tus manos</h2>
        <h2 v-if="idioma=='Inglés'">A record studio in your hands</h2>
      </div>
      <div class="video">
        <iframe
          src="https://www.youtube.com/embed/G-NsOrA2e0E"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div class="bloqueInformacion">
        <div class="introducer">
          <span v-if="idioma=='Español'" >Planteamiento</span>
          <span v-if="idioma=='Inglés'" >Approach</span>
          
        </div>
        <div class="block">
          <div class="title">
            <h3 v-if="idioma=='Español'" >Sobre el proyecto</h3>
            <h3 v-if="idioma=='Inglés'" >About the project</h3>
          </div>
          <p v-if="idioma=='Español'">
            El mercado de la música es internacional, WOW nacía como resultado a
            la relación entre compositores, escritores, cantantes y productores.
            <br />
            <br />
            Un marketplace de instrumentales, un editor para las letras de sus
            canciones, una grabadora con lector de letras, WOW es todo esto, sirvió además para hacer de un TFM un proyecto comercial.
          </p>

          <p v-if="idioma=='Inglés'">
            The music market is international, WOW borned as a result of the relationship between producers, writers, singers and composers.
            <br />
            <br />
            A marketplace of instrumentals, an editor of lyrics, a recorder with lyric reader, WOW it's all this.
          </p>

          
        </div>
        <div class="block">
          <div class="title"><h3 v-if="idioma=='Español'">El equipo</h3> <h3 v-if="idioma=='Inglés'">The team</h3></div>
          <p v-if="idioma=='Español'">
            Como base fue una colaboración entre el estudio Orbitfy a la cabeza
            junto a Nerso Beats, productor de Red Bull Batalla de los Gallos.
          </p>
           <p v-if="idioma=='Inglés'">
            This project was a collaboration between Orbitfy and Nerso Beats, an official beatmaker of Red Bull Batalla de los Gallos.
          </p>
        </div>
        <div class="block">
          <div class="title">
            <h3 v-if="idioma=='Español'">
              ¿Cuál era el problema?
            </h3>
            <h3 v-if="idioma=='Inglés'">
              ¿What where the problem?
            </h3>
          </div>
                <p  v-if="idioma=='Español'">
La solución del marketplace actual que Nerso Beats utilizaba no ofrecía soluciones específicas a los usuarios que aportasen un valor añadido a la venta de beats.          </p>
          <p  v-if="idioma=='Inglés'">
            The currently marketplace solution of Nerso Beats, didn't offered specifics solutions to users, he need give an extra value to sell beats.
          </p>
        </div>
        <div class="fullImg"><img src="../assets/wow/stock.jpg" alt="En la imagen aparece varias personas jóvenes en un estudio de grabación musical" /></div>
        <div class="introducer">
          <span v-if="idioma=='Español'">¡Vamos a ello!</span>
          <span v-if="idioma=='Inglés'">Here we go!</span>
        </div>
        <div class="block">
          <div class="title">
            <h3 v-if="idioma=='Español'">Investigación</h3>
            <h3 v-if="idioma=='Inglés'">Research</h3>
          </div>
          <p v-if="idioma=='Español'">
            Conociendo al público objetivo como si lo hubiésemos parido,
            realizamos grupos de investigación, encuestas, entrevistas,
            analizando su rutina y escenario, así como observando la manera en
            la que utilizaban sus dispositivos, con el fin de comprender como
            era el flujo, desde que sentían la necesidad de escribir una
            canción, como escribían, hasta como hacían las pruebas de grabación.
          </p>
          <p v-if="idioma=='Inglés'">
We know the target client, we made research groups, surveys, interviews, we analyze their routine and scenario. We saw the way to use their devices, we known their userflow, from their felt the need of write a song, how their write, to their made record test.
          </p>
        </div>
        <div class="block">
          <div class="title">
            <h3 v-if="idioma=='Español'">Solución</h3>
            <h3 v-if="idioma=='Inglés'">Solution</h3>
          </div>
          <p v-if="idioma=='Español'">
            Se propone diseñar un entorno de escritura integrado en una
            plataforma donde el usuario pueda redactar canciones y compartirlas
            con otras personas.
            <br />
            <br />
            También se propone diseñar un entorno de grabación para que los
            usuarios puedan hacer pruebas, compartir y exportar. El usuario
            podrá utilizar unos cascos conectados al smartphone, cantar con la
            letra de forma visible, y mezclar con las pistas para tener una
            visión de cómo quedaría y poder compartirlo con quién desee, pues se
            ofrecen funciones sociales básicas.
          </p>

           <p v-if="idioma=='Inglés'">
           We proposed design a writing environment this will integrate an platform where the user could write songs and share its with other people.
            <br />
            <br />
     Also we proposed design a record environment to make song tests, share and export its. Users could use earphones, sing with a visible lyric and mix all these tracks to give a demo and share it with their friends. This app offers social functions basics.
          </p>
        </div>

        <div class="block">
          <div class="title">
            <h3>Testing</h3>
          </div>
          <p v-if="idioma=='Español'">
            Se testean las soluciones con los distintos usuarios, planificando
            tareas concretas y cruciales para los objetivos planteados en un
            entorno real. <br />
            <br />

            Gracias al método de observación, se observaron fallos de
            arquitectura de información que pudieron corregirse en fases
            tempranas, ahorrando así costes futuros.
          </p>

                    <p v-if="idioma=='Inglés'">
       We tested the solutions with different users, we prepared concrete tasks and critical to complete our targets in a real environment. <br />
            <br />

Thanks to the method of observation, we observed many fails at information architecture they could be fixed at early phases, saving future costs.
          </p>
        </div>

        <div class="fullImg">
          <img src="../assets/wow/testing.jpg" alt="Aparecen dos personas realizando un test de producto digital, en este caso una persona sujeta un teléfono mientras la otra observa como lo usa" />
        </div>
        <div class="introducer">
          <span v-if="idioma=='Español'">Funciones destacadas</span>
          <span v-if="idioma=='Inglés'">Some features</span>
          
        </div>
        <div class="block">
          <div class="title">
            
            <h3>Marketplace ++</h3>
          </div>
          <div>
          <p v-if="idioma=='Español'">
            Los productores de musica pueden publicar sus instrumentales y
            comercializarlas libremente en Wow, se proyecta un ranking a medio
            plazo para motivar la competitividad.
            
          </p>

           <p v-if="idioma=='Inglés'">
            Music producers may publish his instrumentals and sell these freely in WOW, we are thinking about a ranked to improve the motivation and competitivity
            
          </p>
          <div class="fullImg"><img src="../assets/wow/marketplace.png" alt="Es una imagen del proyecto WOW, en ella se muestra el marketplace y las funciones que podrán utilizar los usuarios." /></div>
          </div>
        </div>
        <div class="title extrapadding">
          <h3 v-if="idioma=='Español'">Estudio</h3>
          <h3 v-if="idioma=='Inglés'">Studio</h3>
        </div>
        <div class="block2">
          <div>
            <h4>Beats</h4>
            <p v-if="idioma=='Español'">
              En esta funcionalidad el usuario puede escuchar sus beats favoritos y
              comenzar a realizar una grabación.
            </p>
              <p v-if="idioma=='Inglés'">
              On this function the user will listen his favourites beats and could start a rec.
            </p>
            <div class="fullImg">
            <img src="../assets/wow/beats.png" alt="En esta imagen aparece una visualización del listado de beats que los usuarios podrán escuchar" />
            </div>
          </div>
          <div>
            <h4 v-if="idioma=='Español'">Letras</h4>
            <h4 v-if="idioma=='Inglés'">Lyrics</h4>
              <p v-if="idioma=='Español'">
              El usuario puede escribir sus canciones y leerlas mientras graba una prueba de audio.
              
            </p>
            <p v-if="idioma=='Inglés'">
              The user could write his songs and read these while is recording a demo.
              
            </p>
            <div class="fullImg">
            <img src="../assets/wow/letras.png" alt="Esta imagen ilustra el editor de texto de la aplicación, los usuarios podrán utilizarla para escribir sus canciones" />
            </div>
          </div>
          <div>
            <h4 v-if="idioma=='Español'">Grabar</h4>
            <h4 v-if="idioma=='Inglés'">Rec</h4>
            <p v-if="idioma=='Español'">
              El usuario puede utilizar un beat para hacer pruebas de grabación,
              en esta grabación podrá leer su letra.
              
            </p>
             <p v-if="idioma=='Inglés'">
              User could use a beat to make a demo, in this demo the user will read his lyric.
              
            </p>
            <div class="fullImg">
            <img src="../assets/wow/grabar.png" alt="En esta imagen se muestra la interfaz que utilizarán los usuarios para hacer sus pruebas de grabación musical." />
            </div>
          </div>
                    <div>
            <h4 v-if="idioma=='Español'">Reproductor</h4>
            <h4 v-if="idioma=='Inglés'">Music player</h4>
            <p v-if="idioma=='Español'">
             Reproductor basado en los patrones conocidos por los usuarios, añadiendo algún gesto o menú contextual.
              
            </p>
            <p v-if="idioma=='Inglés'">
Music player based in patterns known by users, adding some gesture and contextual menu.              
            </p>
            <div class="fullImg">
            <img src="../assets/wow/reproductor.png" alt="Los usuarios podrán reproducir instrumentales y escuchar sus canciones, esta imagen muestra el reproductor." />
            </div>
          </div>
        </div>
        <div class="block">
          <div class="title">
            <h3 v-if="idioma=='Español'">¿Qué aprendí en este proyecto?</h3>
            <h3 v-if="idioma=='Inglés'">Challenges and learnings</h3>
          </div>
          <p v-if="idioma=='Español'">
Al tratarse de un proyecto End to end con un equipo pequeño, me sirvió para estar en todas las fases del producto, investigando y aterrizando ideas a prototipos de alta calidad. </p>       
          <p v-if="idioma=='Inglés'">
This was an end to end project wit a little team, I was in all product's phases, researching and landing ideas to Hi-Fi prototypes.</p>  
</div>
        
      </div>
          <div id="contact">
<Contact v-bind:idioma="this.idioma"></Contact>
    </div>

      <!-- Fin proyect -->

      <div id="contact">
        <div v-if="idioma == 'Español'" class="contactNew">
          <ContactNew
            v-bind:idioma="this.idioma"
            text="Lo que necesitas, a un clic."
          />
        </div>

        <div v-if="idioma == 'Inglés'" class="contactNew">
          <ContactNew
            v-bind:idioma="this.idioma"
            text="Everything you need is one click away."
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/BackButton";
import Contact from "@/components/Contact.vue"
import ContactNew from "@/components/ContactNew.vue"
export default {
  props: ['idioma'],
  components: {
    BackButton,
    ContactNew
  },created(){
    window.scroll(0,0);
    document.title = 'Wow - Victor Fernández'
     }
};
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";
@import "../scss/projectStyles.scss";
</style>
