<template>
  <div>
    <BackButton
      v-bind:Activartransicion="$attrs.Activartransicion"
      @emisario="ToggleTransition"
    />

    <div id="cookies" class="cookies containerProyect">
      <div v-if="this.idioma == 'Español'">
        <h1>Política de privacidad</h1>
        <p>
          Nos tomamos muy en serio el respeto a la privacidad y la protección de
          los datos personales de nuestros usuarios. A continuación, explicamos
          el tratamiento que realizamos de estos datos.
        </p>
        <h3>1. Datos de los responsables del tratamiento</h3>
        <p>
          Razón Social: Víctor Fernández Fernández <br />
          NIF: 04228231A<br />
          Domicilio: Calle Calera 35,2A, Talavera de la Reina (Toledo)<br />

          Teléfono: 722340710
        </p>

        <p>Email de nuestro Delegado de Protección de Datos: please@vicdesign.me</p>
        <h3>1.1. Normativa aplicable</h3>
        <p>Nuestra Política de Privacidad se ha diseñado de acuerdo con el Reglamento General de Protección de Datos de la UE 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE (Reglamento general de protección de datos), y en lo que no contradiga el mencionado Reglamento, por lo dispuesto en la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal y su normativa de desarrollo.
Al facilitarnos sus datos, usted declara haber leído y conocer la presente Política de Privacidad, prestando su consentimiento inequívoco y expreso al tratamiento de sus datos personales de acuerdo con las finalidades y términos aquí expresados.
Víctor Fernández Fernández podrá modificar la presente política de privacidad para adaptarla a las novedades legislativas, jurisprudenciales o de interpretación de la Agencia Española de Protección de Datos.</p>
<h3>2. Finalidad del tratamiento de los datos personales</h3>   
<p>El tratamiento que realizamos de sus datos personales responde a las siguientes finalidades:</p>

<ul>
  <li>Proporcionarle información relacionada con los servicios que ofrece como Diseñador de productos digitales. Esta información incluirá ofertas, descuentos, información comercial u otras cuestiones que consideremos de su interés, y la podrá recibir por diversas vías, especialmente teléfono y correo electrónico.</li>
  <li>Enviarle nuestras newsletters con las novedades acerca de nuestra entidad y sus servicios.</li>
</ul>
<h3>2.1. ¿Por cuánto tiempo conservaremos sus datos?</h3>
<p>Conservaremos sus datos personales desde que nos dé su consentimiento hasta que lo revoque o bien solicite la limitación del tratamiento. En tales casos, mantendremos sus datos de manera bloqueada durante los plazos legalmente exigidos.</p>  
<h3>3. Legitimación y datos recabados</h3>
<p>La legitimación para el tratamiento de sus datos es el consentimiento del interesado en el momento de facilitarnos la información.</p>
<h3>3.1. Consentimiento para tratar sus datos</h3>
<p>Al rellenar los formularios, marcar la casilla “Acepto la Política de Privacidad” y hacer clic para enviar los datos, o al remitir correos electrónicos a Víctor Fernández Fernández a través de las cuentas habilitadas al efecto, usted manifiesta haber leído y aceptado expresamente la presente política de privacidad, y otorga su consentimiento inequívoco y expreso al tratamiento de sus datos personales conforme a las finalidades informadas.
De acuerdo con las finalidades indicadas en el apartado 2, las categorías de datos que serán objeto de tratamiento son las siguientes:</p>

<li>Datos identificativos: nombre y apellidos, correo electrónico, teléfono y dirección.</li>

<h3>3.2. Datos obligatorios, ¿qué ocurre si no los facilito?</h3>
<p>Los datos de carácter obligatorio se distinguirán en los formularios de recogida de información. Si usted decide no proporcionarnos alguno de esos datos considerados obligatorios no podremos cumplir por con la finalidad prevista y no se realizará el tratamiento de datos ni la prestación del servicio correspondiente.
Usted garantiza que los Datos Personales que nos facilite son veraces y correctos, y será responsable de comunicarnos cualquier modificación en los mismos. En el caso de que los datos aportados pertenecieran a un tercero, usted nos garantiza que ha informado a dicho tercero de los aspectos contenidos en este documento y obtenido su autorización para facilitar sus datos.
</p>
<h3>4. Medidas de seguridad</h3>
<p>Dentro de nuestro compromiso por garantizar la seguridad y confidencialidad de sus datos de carácter personal, le informamos que se han adoptado las medidas de índole técnica y organizativas necesarias para garantizar la seguridad de los datos de carácter personal y evitar su alteración, pérdida, tratamiento o acceso no autorizado, habida cuenta del estado de la tecnología, la naturaleza de los datos almacenados y los riesgos a que estén expuestos. No obstante, lo anterior, usted debe ser consciente de que las medidas de seguridad en Internet no son inexpugnables.
Usted puede obtener más información sobre las medidas de seguridad que aplicamos contactando con nuestro Delegado de Protección de Datos en los canales indicados al inicio de la presente Política de Privacidad en el apartado de identificación del responsable del tratamiento.</p>
<h3>5. Cesión de datos.</h3>

<p>Usted conoce y acepta que sus datos personales podrán ser facilitados a terceros cuando sea necesario para cumplir con las finalidades del tratamiento, respetándose en todos los casos las medidas de seguridad exigidas por la legislación vigente en materia de protección de datos de carácter personal, así como las políticas generales de calidad y privacidad de  Víctor Fernández Fernández.
A excepción de las previsiones contenidas en el párrafo anterior, desde  Víctor Fernández Fernández no se realizarán comunicaciones de sus datos a terceros más allá de las exigidas en la normativa vigente en materia de protección de datos, y sus disposiciones de desarrollo, que las realizadas a las autoridades competentes cuando le fuera requerida.</p>

<h3>6. Derechos del Usuario</h3>
<p>Usted tiene los siguientes derechos en cuanto al tratamiento de sus datos personales:</p>
<ul>
  <li>Derecho a solicitar el acceso a sus datos personales.</li>
  <li>* Derecho a solicitar su rectificación si son inexactos, o bien solicitar su supresión (por ejemplo, si considera que ya no son necesarios para los fines que fueron recogidos).
</li>
<li>Derecho a solicitar la limitación de su tratamiento, siempre que se cumpla alguna de las condiciones previstas en la normativa, en cuyo caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones.</li>
<li>Derecho a oponerse al tratamiento, en cuyo caso dejaremos de tratar sus datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.
</li>
<li>Derecho a la portabilidad de los datos.
</li>
<li>Derecho a presentar una reclamación ante la Agencia Española de Protección de Datos (autoridad de control en materia de Protección de Datos competente), especialmente cuando no haya obtenido satisfacción en el ejercicio de sus derechos.</li>
</ul>
<h3>6.1. ¿Cómo ejercitar mis derechos?</h3>
<p>Usted podrá ejercitar sus derechos u obtener más información sobre los mismos contactando con nuestro Delegado de Protección de Datos en los canales indicados al inicio de la presente Política de Privacidad en el apartado de identificación del Responsable del tratamiento.
Así mismo, y de acuerdo con lo establecido en la Ley 34/2002 de 11 de julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico, en el supuesto de que usted no desee recibir comunicaciones comerciales electrónica en el futuro, podrá manifestar tal deseo enviando un mail a nuestro Delegado de Protección de Datos.</p>
</div>
<!-- Fin Español -->
      <div v-if="this.idioma == 'Inglés'">
        <h1>Privacy Policy</h1>
        <p>
          We take the privacy and protection of our users' personal data very seriously. Below, we explain how we treat this data.
        </p>
        <h3>1. Data of those responsible for the treatment</h3>
        <p>
          Company Name: Víctor Fernández Fernández <br />
          NIF: 04228231A<br />
          Address: Calle Calera 35,2A, Talavera de la Reina (Toledo)<br />
          Telephone: 722340710
    </p>

    <p>Email of our Data Protection Officer: please@vicdesign.me</p>
    <h3>1.1. Applicable regulations</h3>
    <p>Our Privacy Policy has been designed in accordance with the General Data Protection Regulation of the European Union 2016/679 of the European Parliament and of the Council, of April 27, 2016, regarding the protection of individuals with regard to the processing of personal data and the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation), and insofar as it does not contradict said Regulation, for the provisions set forth in Organic Law 15/1999, of December 13, on the Protection of Personal Data and its implementing regulations. By providing us with your data, you declare that you have read and know this Privacy Policy, giving your unequivocal and express consent to the processing of your personal data in accordance with the purposes and terms expressed here. Victor Fernández Fernández may modify this privacy policy to adapt it to legislative, jurisprudential, or interpretation developments of the Spanish Data Protection Agency.</p>

    <h3>2. Purpose of the processing of personal data</h3>   
<p>The processing we carry out of your personal data responds to the following purposes:</p>
<ul>
  <li>Provide you with information related to the services offered as a Digital Product Designer. This information will include offers, discounts, commercial information, or other issues that we consider of your interest, and you may receive it by various means, especially telephone and email.</li>
  <li>Send you our newsletters with news about our entity and its services.</li>
</ul>
<h3>2.1. How long will we keep your data?</h3>
<p>We will keep your personal data from the moment you give us your consent until you revoke it or request the limitation of the treatment. In such cases, we will keep your data blocked for the legally required periods.</p>  
<h3>3. Legitimation and data collected</h3>
<p>The legitimacy for the processing of your data is the consent of the interested party at the time of providing us with the information.</p>
<h3>3.1. Consent to process your data</h3>
<p>By filling in the forms, ticking the "I accept the Privacy Policy" box and clicking to send the data, or by sending emails to Víctor Fernández Fernández through the accounts enabled for this purpose, you declare that you have read and expressly accepted this privacy policy, and give your unequivocal and express consent to the processing of your personal data in accordance with the informed purposes. In accordance with the purposes indicated in section 2, the categories of data that will be subject to processing are as follows:</p>
<li>Identification data: name and surname, email address, telephone and address.</li>
<h3>3.2. Mandatory data, what happens if I do not provide it?</h3>
<p>The required data will be distinguished in the information collection forms. If you decide not to provide us with any of those considered mandatory data, we will not be able to fulfill the intended purpose, and the data will not be processed, nor will the corresponding service be provided. 
You guarantee that the Personal Data provided to us are truthful and accurate, and you will be responsible for communicating any modifications to us. In the event that the provided data belong to a third party, you guarantee that you have informed said third party of the aspects contained in this document and obtained their authorization to provide their data. </p>
<h3>4. Security measures</h3>
<p>As part of our commitment to ensuring the security and confidentiality of your personal data, we inform you that the necessary technical and organizational measures have been adopted to ensure the security of personal data and prevent its alteration, loss, processing or unauthorized access, taking into account the state of technology, the nature of the stored data, and the risks to which they are exposed. However, you must be aware that security measures on the Internet are not impregnable. You can obtain more information about the security measures we apply by contacting our Data Protection Delegate through the channels indicated at the beginning of this Privacy Policy in the section identifying the data controller. </p>
<h3>5. Data disclosure</h3>
<p>You acknowledge and accept that your personal data may be provided to third parties when necessary to comply with the purposes of the processing, always respecting the security measures required by current legislation on the protection of personal data, as well as the general quality and privacy policies of Víctor Fernández Fernández. With the exception of the provisions contained in the previous paragraph, Víctor Fernández Fernández will not disclose your data to third parties beyond those required by current data protection regulations and their implementing provisions, or to competent authorities when required. </p>
<h3>6. User rights</h3>
<p>You have the following rights regarding the processing of your personal data:</p>
<ul>
  <li>Right to request access to your personal data.</li>
  <li>* Right to request rectification if they are inaccurate, or request deletion (for example, if you believe that they are no longer necessary for the purposes for which they were collected).</li>
  <li>Right to request the limitation of their processing, provided that one of the conditions provided for by the regulations is met, in which case we will only keep them for the exercise or defense of claims.</li>
  <li>Right to object to the processing, in which case we will stop processing your data, except for compelling legitimate reasons, or for the exercise or defense of possible claims.</li>
  <li>Right to data portability.</li>
  <li>Right to file a complaint with the Spanish Data Protection Agency (the competent control authority on data protection), especially when you have not obtained satisfaction in the exercise of your rights.</li>
</ul>
<h3>6.1. How to exercise my rights?</h3>
<p>You can exercise your rights or obtain more information about them by contacting our Data Protection Delegate through the channels indicated at the beginning of this Privacy Policy in the section identifying the data controller. Additionally, in accordance with the provisions of Law 34/2002 of July 11, on Services of the Information Society and Electronic Commerce, in the event that you do not wish to receive electronic commercial communications in the future, you may express such desire by sending an email to our Data Protection Delegate. </p>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/BackButton";
import Contact from "@/components/Contact.vue";
export default {
  data() {
    return {
      props: ["idioma"],
      cookies: {
        _ga_ESN444BCHH: true,
        _ga: true,
      },
    };
  },
  methods: {
    deleteAllCookies() {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
      alert("Todas las cookies han sido eliminadas");
    },
  },

  props: ["idioma"],
  components: {
    BackButton,
    Contact,
  },
  created() {
    window.scroll(0, 0);
    document.title = "Cookies - Victor Fernández";
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";
@import "../scss/projectStyles.scss";

.cookies {
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 6rem;
}
li {
  margin: 1rem 0;
}
ul {
  padding-bottom: 1rem;
}
p {
  padding: 1rem 0;
}
ul {
  padding-inline-start: 24px;
}
button {
  padding: 1rem;
  border: solid $primaryBlack 1px;
  font-size: 1rem;
  color: $primaryBlack;
  background-color: $primaryWhite;
}
h4 {
  margin-top: 2rem;
}
</style>