<template>
 <div> 
    <div class="Title" >{{ text }}</div>

 <a v-if="idioma == 'Español'" href="mailto:please@vicdesign.me"><svg width="1715" height="347" viewBox="0 0 1715 347" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.823425 342.199V113.182H35.3774V212.706H149.551V113.182H184.216V342.199H149.551V242.34H35.3774V342.199H0.823425Z" fill="#333333"/>
<path d="M253.883 342.199H217.205L299.62 113.182H339.541L421.956 342.199H385.278L320.531 154.781H318.742L253.883 342.199ZM260.034 252.516H379.016V281.59H260.034V252.516Z" fill="#333333"/>
<path d="M455.029 342.199V113.182H538.898C555.149 113.182 568.606 115.865 579.266 121.233C589.927 126.526 597.904 133.72 603.197 142.815C608.49 151.836 611.137 162.012 611.137 173.344C611.137 182.886 609.385 190.937 605.881 197.498C602.377 203.984 597.68 209.202 591.791 213.153C585.976 217.03 579.565 219.863 572.557 221.652V223.888C580.161 224.261 587.579 226.721 594.81 231.269C602.116 235.742 608.155 242.116 612.926 250.391C617.697 258.666 620.082 268.73 620.082 280.584C620.082 292.288 617.324 302.8 611.807 312.118C606.365 321.363 597.941 328.706 586.535 334.148C575.129 339.516 560.554 342.199 542.811 342.199H455.029ZM489.583 312.566H539.457C556.007 312.566 567.86 309.36 575.017 302.949C582.174 296.537 585.752 288.523 585.752 278.906C585.752 271.675 583.926 265.04 580.273 259.001C576.62 252.963 571.401 248.154 564.617 244.576C557.908 240.998 549.931 239.208 540.687 239.208H489.583V312.566ZM489.583 212.259H535.878C543.631 212.259 550.602 210.768 556.79 207.786C563.052 204.804 568.009 200.629 571.662 195.261C575.39 189.819 577.254 183.408 577.254 176.027C577.254 166.559 573.936 158.62 567.301 152.209C560.666 145.797 550.49 142.592 536.773 142.592H489.583V212.259Z" fill="#333333"/>
<path d="M661.066 342.199V113.182H695.62V312.454H799.394V342.199H661.066Z" fill="#333333"/>
<path d="M838.812 342.199V113.182H982.396V142.927H873.366V212.706H974.903V242.34H873.366V312.454H983.737V342.199H838.812Z" fill="#333333"/>
<path d="M1028.55 113.182H1070.49L1143.4 291.207H1146.08L1218.99 113.182H1260.92V342.199H1228.05V176.475H1225.92L1158.38 341.864H1131.1L1063.55 176.363H1061.43V342.199H1028.55V113.182Z" fill="#333333"/>
<path d="M1510.1 227.69C1510.1 252.143 1505.63 273.166 1496.68 290.76C1487.73 308.279 1475.47 321.773 1459.89 331.24C1444.38 340.634 1426.75 345.33 1407 345.33C1387.16 345.33 1369.46 340.634 1353.88 331.24C1338.37 321.773 1326.15 308.242 1317.2 290.648C1308.25 273.054 1303.78 252.068 1303.78 227.69C1303.78 203.238 1308.25 182.252 1317.2 164.733C1326.15 147.139 1338.37 133.646 1353.88 124.252C1369.46 114.784 1387.16 110.051 1407 110.051C1426.75 110.051 1444.38 114.784 1459.89 124.252C1475.47 133.646 1487.73 147.139 1496.68 164.733C1505.63 182.252 1510.1 203.238 1510.1 227.69ZM1475.88 227.69C1475.88 209.053 1472.86 193.36 1466.82 180.612C1460.86 167.79 1452.66 158.098 1442.22 151.538C1431.86 144.903 1420.12 141.585 1407 141.585C1393.8 141.585 1382.02 144.903 1371.66 151.538C1361.3 158.098 1353.1 167.79 1347.06 180.612C1341.09 193.36 1338.11 209.053 1338.11 227.69C1338.11 246.328 1341.09 262.058 1347.06 274.881C1353.1 287.629 1361.3 297.32 1371.66 303.955C1382.02 310.516 1393.8 313.796 1407 313.796C1420.12 313.796 1431.86 310.516 1442.22 303.955C1452.66 297.32 1460.86 287.629 1466.82 274.881C1472.86 262.058 1475.88 246.328 1475.88 227.69Z" fill="#333333"/>
<path d="M1678.09 173.344C1676.89 162.757 1671.97 154.557 1663.33 148.742C1654.68 142.853 1643.79 139.908 1630.67 139.908C1621.28 139.908 1613.15 141.399 1606.3 144.381C1599.44 147.288 1594.11 151.314 1590.3 156.458C1586.58 161.527 1584.71 167.305 1584.71 173.791C1584.71 179.233 1585.98 183.93 1588.52 187.881C1591.12 191.832 1594.52 195.149 1598.69 197.833C1602.94 200.442 1607.49 202.642 1612.33 204.431C1617.18 206.146 1621.84 207.562 1626.31 208.68L1648.68 214.495C1655.98 216.284 1663.48 218.707 1671.15 221.764C1678.83 224.82 1685.95 228.846 1692.51 233.841C1699.07 238.836 1704.37 245.023 1708.39 252.404C1712.49 259.784 1714.54 268.618 1714.54 278.906C1714.54 291.878 1711.19 303.396 1704.48 313.46C1697.84 323.525 1688.19 331.464 1675.52 337.279C1662.92 343.094 1647.67 346.001 1629.78 346.001C1612.63 346.001 1597.8 343.28 1585.27 337.838C1572.75 332.396 1562.94 324.68 1555.86 314.69C1548.78 304.626 1544.87 292.698 1544.12 278.906H1578.79C1579.46 287.181 1582.14 294.077 1586.84 299.594C1591.61 305.036 1597.68 309.099 1605.07 311.783C1612.52 314.392 1620.68 315.697 1629.55 315.697C1639.32 315.697 1648.01 314.169 1655.61 311.112C1663.29 307.981 1669.33 303.657 1673.73 298.14C1678.12 292.549 1680.32 286.026 1680.32 278.571C1680.32 271.787 1678.39 266.233 1674.51 261.909C1670.71 257.585 1665.53 254.007 1658.97 251.174C1652.48 248.341 1645.14 245.843 1636.94 243.681L1609.87 236.301C1591.53 231.306 1577 223.963 1566.26 214.271C1555.6 204.58 1550.27 191.757 1550.27 175.804C1550.27 162.608 1553.85 151.09 1561.01 141.25C1568.16 131.409 1577.85 123.768 1590.08 118.326C1602.31 112.809 1616.1 110.051 1631.46 110.051C1646.96 110.051 1660.64 112.772 1672.5 118.214C1684.42 123.656 1693.82 131.148 1700.68 140.691C1707.53 150.158 1711.11 161.043 1711.41 173.344H1678.09Z" fill="#333333"/>
<circle cx="1309.66" cy="103.663" r="102.472" transform="rotate(-30 1309.66 103.663)" fill="#F0F0F0" stroke="black"/>
<circle cx="1309.86" cy="103.715" r="64.8123" transform="rotate(-30 1309.86 103.715)" stroke="#333333"/>
<circle cx="1309.86" cy="103.715" r="59.5346" transform="rotate(-30 1309.86 103.715)" fill="#F0F0F0" stroke="#F0F0F0"/>
<ellipse cx="1281.44" cy="98.031" rx="10.5555" ry="23.7499" transform="rotate(-30 1281.44 98.031)" fill="#333333"/>
<ellipse cx="1320.86" cy="75.271" rx="11.2153" ry="23.7499" transform="rotate(-30 1320.86 75.271)" fill="#333333"/>
<path d="M1351.89 80.2061C1365.01 102.925 1356.97 132.123 1333.94 145.422C1310.9 158.721 1281.6 151.085 1268.48 128.366" stroke="#333333"/>
<path d="M1271.33 21.9808L1277.58 34.9183L1276.72 35.3395L1270.47 22.402L1271.33 21.9808Z" fill="black"/>
<path d="M1281.54 29.257L1281.29 28.5162L1281.92 27.2805C1283.44 24.3549 1284.07 22.8612 1283.62 21.5268C1283.31 20.6305 1282.59 19.9525 1281.27 20.4006C1280.47 20.6765 1279.94 21.3137 1279.65 21.7906L1278.99 21.079C1279.42 20.353 1280.16 19.6732 1281.18 19.3239C1283.09 18.6686 1284.34 19.7057 1284.77 20.9724C1285.33 22.6095 1284.59 24.3284 1283.35 26.7605L1282.87 27.6567L1282.88 27.6864L1286.85 26.3228L1287.2 27.32L1281.54 29.257Z" fill="black"/>
<path d="M1292.13 26.0398L1291.53 23.5683L1287.31 24.5989L1287.1 23.7865L1289.74 16.9979L1291.07 16.6733L1292.44 22.3194L1293.71 22.007L1293.95 22.9678L1292.68 23.2802L1293.29 25.7517L1292.13 26.0398ZM1291.3 22.6027L1290.56 19.5683C1290.44 19.0944 1290.34 18.611 1290.25 18.1311L1290.21 18.1419C1290.06 18.7395 1289.94 19.1846 1289.79 19.6704L1288.32 23.3055L1288.33 23.3352L1291.3 22.6027Z" fill="black"/>
<path d="M1294.78 26.0272L1297.03 15.0921L1297.99 14.9357L1295.72 25.872L1294.78 26.0272Z" fill="black"/>
<path d="M1305.22 14.7202L1305.29 15.5533L1302 24.3958L1300.7 24.517L1304 15.9229L1304 15.8932L1299.44 16.2999L1299.35 15.2576L1305.22 14.7202Z" fill="black"/>
<path d="M1312.52 14.3727C1313.28 14.3051 1314.19 14.2891 1315.18 14.3501C1316.98 14.4741 1318.22 14.975 1319.02 15.8204C1319.83 16.6692 1320.25 17.8206 1320.15 19.3857C1320.04 20.9639 1319.46 22.2267 1318.5 23.049C1317.54 23.8927 1316.01 24.2764 1314.13 24.1528C1313.24 24.0901 1312.5 23.9971 1311.88 23.8846L1312.52 14.3727ZM1313.18 23.0073C1313.49 23.0832 1313.95 23.1287 1314.44 23.1682C1317.11 23.3512 1318.65 21.9533 1318.83 19.3488C1319 17.0712 1317.81 15.5321 1315.17 15.3512C1314.53 15.3042 1314.03 15.3337 1313.7 15.384L1313.18 23.0073Z" fill="black"/>
<path d="M1322.41 21.6205C1322.15 23.3111 1323.11 24.1938 1324.35 24.407C1325.25 24.5552 1325.81 24.4956 1326.32 24.3792L1326.38 25.3075C1325.9 25.4261 1325.11 25.5295 1324.03 25.337C1321.91 24.9743 1320.89 23.3738 1321.25 21.2951C1321.61 19.2295 1323.11 17.7997 1325.1 18.1523C1327.34 18.5418 1327.59 20.6094 1327.38 21.8718C1327.34 22.1276 1327.28 22.3205 1327.24 22.4431L1322.41 21.6205ZM1326.22 21.3574C1326.37 20.5625 1326.25 19.2731 1324.84 19.0356C1323.57 18.8237 1322.83 19.8863 1322.57 20.7304L1326.22 21.3574Z" fill="black"/>
<path d="M1328.2 24.7C1328.49 25.0257 1329.08 25.4416 1329.69 25.5982C1330.58 25.8237 1331.12 25.4824 1331.25 24.929C1331.4 24.3446 1331.13 23.9356 1330.31 23.3709C1329.21 22.6339 1328.8 21.8166 1329 21.0143C1329.28 19.924 1330.38 19.2569 1331.83 19.625C1332.51 19.7943 1333.06 20.1438 1333.39 20.4634L1332.85 21.2813C1332.63 21.0457 1332.19 20.6981 1331.57 20.538C1330.85 20.3581 1330.34 20.6719 1330.21 21.1695C1330.07 21.7277 1330.41 22.0805 1331.2 22.6346C1332.26 23.3741 1332.7 24.1117 1332.46 25.1186C1332.15 26.3006 1331.02 26.9096 1329.43 26.5029C1328.69 26.3196 1328.05 25.9586 1327.64 25.5621L1328.2 24.7Z" fill="black"/>
<path d="M1333.42 27.4398L1335.49 20.7964L1336.7 21.175L1334.62 27.8185L1333.42 27.4398ZM1337.42 19.3539C1337.3 19.7729 1336.9 20.0078 1336.41 19.8566C1335.99 19.7229 1335.79 19.297 1335.92 18.8898C1336.05 18.4695 1336.47 18.2299 1336.92 18.3705C1337.37 18.5064 1337.55 18.9253 1337.42 19.3539Z" fill="black"/>
<path d="M1344.59 24.0682C1344.38 24.5316 1344.15 25.0391 1343.84 25.8264L1342.38 29.5947C1341.8 31.0811 1341.15 31.8758 1340.31 32.197C1339.44 32.5409 1338.46 32.3764 1337.64 32.0519C1336.87 31.7462 1336.08 31.2266 1335.7 30.6743L1336.34 29.8927C1336.67 30.324 1337.24 30.8351 1338.05 31.143C1339.25 31.612 1340.38 31.3252 1341.02 29.6935L1341.3 28.9682L1341.27 28.9612C1340.68 29.4263 1339.79 29.6383 1338.79 29.2433C1337.18 28.6227 1336.56 26.8005 1337.26 25.0021C1338.11 22.8035 1340.03 22.1173 1341.52 22.698C1342.64 23.1329 1343.03 23.9598 1343.1 24.617L1343.13 24.624L1343.56 23.6662L1344.59 24.0682ZM1342.37 26.1552C1342.45 25.9575 1342.51 25.7777 1342.52 25.5969C1342.57 24.8251 1342.21 24.0479 1341.35 23.7129C1340.23 23.278 1339.05 23.911 1338.47 25.4104C1337.97 26.6812 1338.2 27.9904 1339.47 28.4936C1340.2 28.7722 1341.04 28.5758 1341.58 27.9207C1341.72 27.7488 1341.84 27.5272 1341.92 27.3247L1342.37 26.1552Z" fill="black"/>
<path d="M1345.92 26.7326C1346.24 26.0903 1346.49 25.5601 1346.71 25.0229L1347.71 25.5297L1347.26 26.5875L1347.29 26.5945C1347.89 26.1591 1348.91 25.9477 1349.93 26.4615C1350.79 26.8952 1351.87 28.0888 1350.79 30.2147L1348.92 33.9218L1347.79 33.3454L1349.59 29.767C1350.1 28.7661 1350.15 27.741 1349.09 27.2036C1348.34 26.8276 1347.5 27.0585 1346.99 27.5957C1346.87 27.7104 1346.74 27.8941 1346.65 28.08L1344.75 31.8312L1343.62 31.2548L1345.92 26.7326Z" fill="black"/>
<path d="M1350.53 34.9074L1354.09 28.9302L1355.17 29.5781L1351.61 35.5553L1350.53 34.9074ZM1356.3 27.9776C1356.09 28.3506 1355.64 28.4893 1355.21 28.2321C1354.82 28.002 1354.73 27.5434 1354.95 27.1739C1355.18 26.7865 1355.64 26.6643 1356.03 26.9028C1356.45 27.1399 1356.53 27.5901 1356.3 27.9776Z" fill="black"/>
<path d="M1356.17 32.3571C1356.58 31.7655 1356.9 31.2695 1357.2 30.7796L1358.12 31.4198L1357.53 32.4045L1357.55 32.4247C1358.21 32.0757 1359.24 32.001 1360.19 32.6614C1360.98 33.2107 1361.88 34.5395 1360.52 36.4978L1358.15 39.9122L1357.12 39.1928L1359.41 35.8904C1360.05 34.9674 1360.23 33.9643 1359.25 33.2802C1358.56 32.8018 1357.69 32.9269 1357.12 33.3825C1356.98 33.4806 1356.84 33.6441 1356.71 33.8147L1354.33 37.2601L1353.29 36.5407L1356.17 32.3571Z" fill="black"/>
<path d="M1368.67 39.5944C1368.33 39.9585 1367.94 40.3728 1367.39 41.0196L1364.78 44.1011C1363.74 45.3184 1362.87 45.8562 1361.96 45.8804C1361.04 45.9273 1360.16 45.4473 1359.49 44.882C1358.86 44.3403 1358.29 43.5974 1358.1 42.9431L1358.96 42.4102C1359.13 42.9243 1359.51 43.5884 1360.17 44.1502C1361.16 44.984 1362.32 45.0906 1363.45 43.7468L1363.96 43.1584L1363.93 43.1383C1363.22 43.3828 1362.31 43.287 1361.49 42.5892C1360.17 41.4703 1360.18 39.5501 1361.43 38.0881C1362.96 36.2872 1364.99 36.2639 1366.21 37.2988C1367.13 38.0854 1367.23 38.9897 1367.08 39.6252L1367.11 39.6453L1367.83 38.8848L1368.67 39.5944ZM1365.9 40.8432C1366.03 40.6762 1366.14 40.5235 1366.21 40.3603C1366.52 39.6551 1366.44 38.7923 1365.73 38.195C1364.81 37.4085 1363.49 37.6296 1362.45 38.86C1361.57 39.9006 1361.35 41.2189 1362.39 42.1012C1362.98 42.6027 1363.84 42.6845 1364.57 42.2497C1364.77 42.1263 1364.95 41.965 1365.09 41.798L1365.9 40.8432Z" fill="black"/>
<path d="M1376.16 44.3712L1374.67 45.7142L1375.89 47.0582L1375.18 47.7022L1373.96 46.3582L1371.17 48.8745C1370.53 49.4539 1370.33 49.9602 1370.81 50.4812C1371.03 50.7298 1371.23 50.8812 1371.36 50.9673L1370.7 51.6516C1370.45 51.5373 1370.15 51.2938 1369.82 50.9314C1369.42 50.4957 1369.24 50.0126 1369.29 49.5629C1369.33 49.0456 1369.7 48.5291 1370.31 47.9902L1373.12 45.4464L1372.4 44.6483L1373.11 44.0043L1373.84 44.8024L1375.08 43.6888L1376.16 44.3712Z" fill="black"/>
<path d="M1379.63 46.4176L1380.4 47.4236L1376.96 50.0736L1376.97 50.0985C1377.38 50.0396 1377.82 50.0947 1378.23 50.2676C1378.63 50.4371 1379.01 50.7017 1379.31 51.0749C1379.88 51.8178 1380.33 53.3495 1378.43 54.812L1375.15 57.3365L1374.38 56.3305L1377.55 53.8945C1378.44 53.2141 1378.93 52.3 1378.21 51.3556C1377.71 50.7027 1376.85 50.5437 1376.17 50.7821C1375.99 50.8317 1375.84 50.931 1375.64 51.0805L1372.31 53.642L1371.54 52.636L1379.63 46.4176Z" fill="black"/>
<path d="M1379.32 57.717C1377.89 58.6532 1377.88 59.9551 1378.56 61.0269C1379.04 61.788 1379.47 62.1701 1379.89 62.4583L1379.24 63.1199C1378.83 62.8483 1378.23 62.3302 1377.64 61.3995C1376.5 59.5915 1377 57.7536 1378.78 56.6375C1380.55 55.5132 1382.61 55.6777 1383.69 57.3861C1384.91 59.3055 1383.54 60.8822 1382.47 61.5695C1382.25 61.7072 1382.06 61.7912 1381.94 61.85L1379.32 57.717ZM1382.07 60.3692C1382.76 59.95 1383.63 58.9914 1382.87 57.7833C1382.19 56.7032 1380.89 56.8588 1380.09 57.2416L1382.07 60.3692Z" fill="black"/>
<path d="M1381.92 68.7913L1387.34 66.2157L1386.92 65.3307L1387.79 64.9178L1388.21 65.8029L1388.51 65.6648C1389.39 65.2424 1390.29 65.0553 1391.05 65.3491C1391.66 65.5782 1392.11 66.0756 1392.36 66.6059C1392.55 67.007 1392.63 67.4013 1392.64 67.6628L1391.68 67.9263C1391.68 67.7158 1391.63 67.4569 1391.48 67.1354C1391.02 66.1625 1390.04 66.3154 1389.08 66.781L1388.74 66.943L1389.47 68.4628L1388.6 68.8757L1387.88 67.3559L1382.46 69.9315L1381.92 68.7913Z" fill="black"/>
<path d="M1387.45 77.031C1386.79 77.2943 1386.2 77.5312 1385.71 77.7711L1385.3 76.7219L1386.34 76.2477L1386.32 76.2228C1385.68 76.1199 1384.74 75.6996 1384.28 74.546C1383.88 73.5265 1383.97 72.091 1386.25 71.207L1390.04 69.7385L1390.49 70.9135L1386.91 72.3098C1385.68 72.7873 1384.99 73.4859 1385.41 74.56C1385.71 75.3445 1386.48 75.6895 1387.08 75.6963C1387.28 75.693 1387.51 75.6575 1387.73 75.5674L1391.7 74.0231L1392.16 75.1981L1387.45 77.031Z" fill="black"/>
<path d="M1395.27 78.43L1393.35 79.0045L1393.88 80.738L1392.95 81.0168L1392.43 79.2833L1388.83 80.3622C1388 80.6096 1387.61 80.9894 1387.81 81.6603C1387.91 81.9809 1388.02 82.1932 1388.11 82.3426L1387.21 82.6712C1387.05 82.4662 1386.87 82.1163 1386.72 81.6485C1386.55 81.0833 1386.59 80.5743 1386.84 80.1856C1387.09 79.7279 1387.65 79.4282 1388.44 79.1964L1392.08 78.1018L1391.77 77.0641L1392.69 76.7853L1393 77.8231L1394.6 77.3452L1395.27 78.43Z" fill="black"/>
<path d="M1390.65 89.2285C1389.95 89.3778 1389.34 89.5351 1388.81 89.6956L1388.57 88.5933L1389.66 88.2829L1389.66 88.2532C1389.04 88.0455 1388.17 87.4882 1387.9 86.2803C1387.67 85.2077 1387.97 83.8022 1390.37 83.2851L1394.35 82.4149L1394.62 83.6525L1390.85 84.4754C1389.56 84.7594 1388.78 85.3431 1389.02 86.4619C1389.2 87.2886 1389.91 87.7444 1390.5 87.8464C1390.69 87.8777 1390.93 87.8718 1391.16 87.8197L1395.33 86.9035L1395.6 88.1411L1390.65 89.2285Z" fill="black"/>
<path d="M1393.81 90.8382C1394.62 90.7252 1395.31 90.6104 1395.95 90.4863L1396.1 91.5854L1394.75 91.8208L1394.76 91.8802C1395.73 92.0626 1396.42 92.7384 1396.54 93.5772C1396.56 93.7222 1396.56 93.8209 1396.55 93.9374L1395.37 94.0984C1395.38 93.9688 1395.38 93.8404 1395.35 93.6657C1395.23 92.7841 1394.48 92.2453 1393.51 92.2044C1393.33 92.1981 1393.13 92.2013 1392.92 92.2273L1389.24 92.7359L1389.06 91.4966L1393.81 90.8382Z" fill="black"/>
<path d="M1392.94 96.3315C1391.24 96.4722 1390.6 97.614 1390.69 98.8716C1390.75 99.7785 1390.94 100.308 1391.17 100.77L1390.28 101.043C1390.05 100.61 1389.77 99.8672 1389.69 98.7595C1389.55 96.6164 1390.87 95.2576 1392.96 95.1103C1395.06 94.9631 1396.79 96.1003 1396.92 98.115C1397.07 100.386 1395.12 101.118 1393.85 101.199C1393.59 101.214 1393.38 101.201 1393.26 101.195L1392.94 96.3315ZM1394.09 99.9728C1394.89 99.9288 1396.12 99.5072 1396.03 98.0916C1395.94 96.8127 1394.72 96.3319 1393.85 96.2941L1394.09 99.9728Z" fill="black"/>
<path d="M1400.64 108.877L1386.27 108.137L1386.33 107.177L1400.69 107.916L1400.64 108.877Z" fill="black"/>
<path d="M1388.66 118.572L1389.41 118.746L1390.13 119.927C1391.81 122.763 1392.75 124.081 1394.11 124.411C1395.03 124.622 1395.99 124.367 1396.3 123.013C1396.49 122.186 1396.23 121.407 1395.97 120.903L1396.93 120.712C1397.32 121.462 1397.5 122.451 1397.27 123.504C1396.82 125.468 1395.29 125.99 1393.98 125.69C1392.3 125.302 1391.22 123.777 1389.81 121.435L1389.3 120.556L1389.28 120.549L1388.34 124.641L1387.32 124.402L1388.66 118.572Z" fill="black"/>
<path d="M1385.81 129.247L1388.23 130.029L1389.57 125.903L1390.36 126.165L1394.75 131.965L1394.33 133.262L1388.8 131.472L1388.4 132.712L1387.46 132.403L1387.86 131.164L1385.44 130.381L1385.81 129.247ZM1389.17 130.337L1392.14 131.302C1392.61 131.454 1393.07 131.62 1393.53 131.798L1393.54 131.759C1393.11 131.321 1392.79 130.97 1392.47 130.595L1390.15 127.44L1390.12 127.433L1389.17 130.337Z" fill="black"/>
<path d="M1384.43 131.528L1392.56 139.187L1392.18 140.09L1384.06 132.418L1384.43 131.528Z" fill="black"/>
<path d="M1388.56 146.354L1387.81 145.978L1382.01 138.536L1382.6 137.367L1388.19 144.689L1388.21 144.696L1390.25 140.605L1391.19 141.073L1388.56 146.354Z" fill="black"/>
<path d="M1385.03 152.728C1384.69 153.414 1384.23 154.202 1383.65 155.01C1382.61 156.477 1381.52 157.267 1380.39 157.503C1379.24 157.744 1378.04 157.498 1376.76 156.595C1375.48 155.676 1374.71 154.529 1374.51 153.269C1374.3 152.006 1374.78 150.505 1375.87 148.966C1376.39 148.244 1376.86 147.653 1377.29 147.185L1385.03 152.728ZM1377.34 148.771C1377.1 148.995 1376.83 149.364 1376.55 149.759C1374.99 151.939 1375.37 153.982 1377.5 155.508C1379.35 156.853 1381.28 156.642 1382.82 154.484C1383.19 153.959 1383.43 153.533 1383.57 153.211L1377.34 148.771Z" fill="black"/>
<path d="M1373.68 157.348C1372.37 156.241 1371.12 156.586 1370.29 157.534C1369.68 158.214 1369.45 158.727 1369.28 159.222L1368.47 158.786C1368.6 158.318 1368.94 157.593 1369.67 156.759C1371.09 155.154 1373 155.13 1374.57 156.513C1376.14 157.895 1376.56 159.923 1375.23 161.446C1373.72 163.147 1371.82 162.282 1370.87 161.429C1370.67 161.256 1370.55 161.104 1370.45 161.003L1373.68 157.348ZM1371.9 160.731C1372.49 161.275 1373.66 161.841 1374.61 160.775C1375.45 159.809 1374.94 158.612 1374.35 157.956L1371.9 160.731Z" fill="black"/>
<path d="M1368 160.668C1367.57 160.755 1366.91 161.022 1366.45 161.466C1365.79 162.103 1365.8 162.737 1366.19 163.152C1366.61 163.586 1367.1 163.578 1368.01 163.176C1369.22 162.631 1370.13 162.718 1370.7 163.315C1371.48 164.127 1371.47 165.416 1370.39 166.451C1369.89 166.941 1369.3 167.226 1368.86 167.333L1368.45 166.452C1368.77 166.39 1369.29 166.2 1369.76 165.755C1370.3 165.233 1370.29 164.636 1369.94 164.267C1369.54 163.853 1369.06 163.955 1368.16 164.339C1366.97 164.849 1366.11 164.841 1365.39 164.102C1364.54 163.221 1364.62 161.945 1365.82 160.791C1366.36 160.264 1367 159.908 1367.55 159.776L1368 160.668Z" fill="black"/>
<path d="M1362.94 163.635L1367.5 168.897L1366.54 169.728L1361.98 164.466L1362.94 163.635ZM1367.7 171.303C1367.41 170.985 1367.43 170.52 1367.8 170.193C1368.14 169.898 1368.6 169.952 1368.89 170.287C1369.18 170.625 1369.16 171.104 1368.8 171.408C1368.45 171.7 1368 171.641 1367.7 171.303Z" fill="black"/>
<path d="M1359.9 174.91C1359.63 174.493 1359.31 174.022 1358.8 173.344L1356.38 170.114C1355.42 168.84 1355.09 167.86 1355.27 166.981C1355.43 166.07 1356.09 165.322 1356.79 164.794C1357.46 164.299 1358.32 163.904 1358.99 163.868L1359.32 164.832C1358.78 164.877 1358.04 165.101 1357.36 165.619C1356.32 166.394 1355.97 167.506 1357.02 168.912L1357.49 169.528L1357.51 169.513C1357.43 168.762 1357.72 167.899 1358.59 167.254C1359.97 166.222 1361.84 166.651 1363 168.197C1364.41 170.083 1363.99 172.075 1362.7 173.037C1361.74 173.76 1360.83 173.651 1360.24 173.373L1360.22 173.388L1360.8 174.259L1359.9 174.91ZM1359.3 171.927C1359.43 172.095 1359.55 172.242 1359.7 172.345C1360.33 172.793 1361.18 172.909 1361.92 172.358C1362.89 171.634 1362.97 170.302 1362.01 169.012C1361.18 167.914 1359.95 167.422 1358.86 168.239C1358.24 168.705 1357.97 169.516 1358.23 170.329C1358.3 170.544 1358.42 170.76 1358.55 170.928L1359.3 171.927Z" fill="black"/>
<path d="M1356.94 174.637C1357.32 175.25 1357.63 175.742 1357.97 176.211L1357.02 176.797L1356.36 175.848L1356.33 175.863C1356.39 176.607 1356.03 177.577 1355.05 178.177C1354.22 178.678 1352.64 178.966 1351.4 176.927L1349.23 173.386L1350.32 172.728L1352.4 176.15C1352.99 177.107 1353.83 177.683 1354.85 177.064C1355.56 176.631 1355.8 175.791 1355.62 175.076C1355.59 174.907 1355.5 174.714 1355.39 174.523L1353.21 170.957L1354.29 170.299L1356.94 174.637Z" fill="black"/>
<path d="M1350.43 182.8C1350.22 182.427 1350.34 181.977 1350.78 181.744C1351.18 181.54 1351.62 181.694 1351.82 182.077C1352.02 182.469 1351.89 182.932 1351.48 183.145C1351.06 183.368 1350.64 183.2 1350.43 182.8ZM1347.57 174.25L1350.77 180.422L1349.65 181.005L1346.44 174.833L1347.57 174.25Z" fill="black"/>
<path d="M1346.75 180.397C1347.05 181.058 1347.29 181.593 1347.56 182.101L1346.53 182.549L1346.01 181.524L1345.98 181.539C1345.94 182.284 1345.45 183.198 1344.4 183.661C1343.52 184.049 1341.92 184.119 1340.96 181.926L1339.29 178.118L1340.45 177.615L1342.06 181.293C1342.51 182.319 1343.26 183.012 1344.36 182.534C1345.13 182.2 1345.47 181.402 1345.4 180.672C1345.38 180.501 1345.32 180.294 1345.24 180.097L1343.57 176.26L1344.73 175.756L1346.75 180.397Z" fill="black"/>
<path d="M1334.04 187.228C1333.91 186.738 1333.77 186.201 1333.5 185.393L1332.26 181.551C1331.76 180.03 1331.76 179.004 1332.21 178.221C1332.66 177.408 1333.53 176.918 1334.37 176.644C1335.16 176.386 1336.11 176.295 1336.75 176.476L1336.75 177.485C1336.22 177.358 1335.46 177.33 1334.64 177.594C1333.41 177.994 1332.71 178.93 1333.25 180.598L1333.49 181.335L1333.52 181.329C1333.69 180.589 1334.24 179.873 1335.27 179.537C1336.91 179.008 1338.53 180.019 1339.14 181.851C1339.87 184.096 1338.82 185.842 1337.3 186.333C1336.15 186.707 1335.32 186.31 1334.86 185.848L1334.83 185.854L1335.1 186.873L1334.04 187.228ZM1334.44 184.207C1334.51 184.413 1334.58 184.581 1334.69 184.729C1335.14 185.355 1335.91 185.737 1336.78 185.458C1337.93 185.084 1338.44 183.847 1337.94 182.309C1337.52 181.011 1336.51 180.144 1335.21 180.562C1334.48 180.799 1333.96 181.487 1333.95 182.334C1333.94 182.558 1334 182.803 1334.06 183.01L1334.44 184.207Z" fill="black"/>
<path d="M1326.04 191.084L1325.68 189.122L1323.9 189.452L1323.73 188.5L1325.51 188.17L1324.83 184.479C1324.67 183.63 1324.34 183.198 1323.65 183.322C1323.32 183.38 1323.09 183.467 1322.94 183.54L1322.72 182.617C1322.95 182.475 1323.31 182.333 1323.79 182.244C1324.37 182.14 1324.87 182.239 1325.23 182.518C1325.65 182.819 1325.89 183.41 1326.04 184.209L1326.73 187.942L1327.79 187.75L1327.96 188.701L1326.9 188.894L1327.2 190.533L1326.04 191.084Z" fill="black"/>
<path d="M1322.45 192.965L1321.19 193.097L1320.75 188.782L1320.72 188.788C1320.56 189.167 1320.27 189.511 1319.91 189.764C1319.56 190.021 1319.13 190.202 1318.66 190.251C1317.73 190.346 1316.19 189.932 1315.94 187.542L1315.51 183.428L1316.76 183.301L1317.18 187.278C1317.29 188.397 1317.81 189.293 1318.99 189.175C1319.81 189.092 1320.39 188.455 1320.55 187.746C1320.6 187.571 1320.6 187.374 1320.57 187.135L1320.14 182.957L1321.39 182.83L1322.45 192.965Z" fill="black"/>
<path d="M1313.04 186.767C1312.99 185.056 1311.9 184.362 1310.62 184.378C1309.72 184.389 1309.17 184.551 1308.7 184.76L1308.47 183.859C1308.92 183.647 1309.68 183.417 1310.79 183.398C1312.93 183.376 1314.22 184.768 1314.25 186.866C1314.28 188.964 1313.06 190.631 1311.02 190.654C1308.75 190.683 1308.13 188.685 1308.11 187.41C1308.1 187.149 1308.14 186.954 1308.15 186.824L1313.04 186.767ZM1309.34 187.711C1309.34 188.518 1309.69 189.763 1311.11 189.751C1312.39 189.735 1312.94 188.551 1313.03 187.669L1309.34 187.711Z" fill="black"/>
<path d="M1302.26 183.141L1301.58 189.099L1302.56 189.213L1302.45 190.173L1301.47 190.059L1301.43 190.392C1301.32 191.365 1301.01 192.23 1300.36 192.716C1299.84 193.116 1299.19 193.237 1298.6 193.169C1298.16 193.114 1297.78 192.978 1297.55 192.846L1297.83 191.897C1298 192.007 1298.26 192.103 1298.61 192.142C1299.69 192.262 1300.06 191.351 1300.18 190.296L1300.22 189.928L1298.55 189.741L1298.66 188.781L1300.33 188.968L1301.01 183.01L1302.26 183.141Z" fill="black"/>
<path d="M1292.31 183.498C1292.44 182.79 1292.54 182.165 1292.6 181.625L1293.7 181.829L1293.57 182.958L1293.59 182.965C1294.02 182.476 1294.88 181.892 1296.09 182.12C1297.17 182.317 1298.34 183.157 1297.89 185.555L1297.14 189.551L1295.9 189.316L1296.61 185.528C1296.85 184.225 1296.62 183.273 1295.49 183.066C1294.65 182.906 1293.96 183.381 1293.64 183.89C1293.54 184.059 1293.45 184.275 1293.4 184.501L1292.62 188.697L1291.38 188.462L1292.31 183.498Z" fill="black"/>
<path d="M1287.04 189.405L1287.55 187.481L1285.8 187.01L1286.05 186.081L1287.8 186.551L1288.78 182.924C1289 182.086 1288.89 181.551 1288.21 181.369C1287.9 181.285 1287.64 181.266 1287.48 181.258L1287.67 180.327C1287.93 180.299 1288.33 180.327 1288.81 180.448C1289.37 180.608 1289.79 180.901 1289.98 181.318C1290.23 181.771 1290.2 182.4 1289.99 183.199L1289 186.865L1290.05 187.146L1289.8 188.076L1288.76 187.79L1288.33 189.401L1287.04 189.405Z" fill="black"/>
<path d="M1280.28 179.792C1280.52 179.112 1280.72 178.512 1280.86 177.983L1281.92 178.358L1281.61 179.454L1281.64 179.461C1282.14 179.041 1283.08 178.598 1284.25 179.009C1285.28 179.376 1286.31 180.375 1285.49 182.683L1284.13 186.522L1282.94 186.104L1284.22 182.476C1284.66 181.225 1284.58 180.255 1283.49 179.874C1282.69 179.596 1281.94 179.95 1281.54 180.403C1281.41 180.557 1281.29 180.758 1281.22 180.977L1279.8 185.009L1278.6 184.591L1280.28 179.792Z" fill="black"/>
<path d="M1277.24 181.636C1276.91 182.39 1276.64 183.034 1276.42 183.648L1275.41 183.202L1275.92 181.935L1275.86 181.912C1275.2 182.639 1274.26 182.872 1273.48 182.532C1273.35 182.475 1273.27 182.425 1273.18 182.344L1273.65 181.251C1273.76 181.33 1273.87 181.392 1274.03 181.464C1274.85 181.823 1275.7 181.463 1276.24 180.666C1276.33 180.523 1276.44 180.349 1276.52 180.146L1278.02 176.75L1279.16 177.253L1277.24 181.636Z" fill="black"/>
<path d="M1273.02 178.008C1273.8 176.475 1273.16 175.35 1272.04 174.756C1271.24 174.331 1270.68 174.223 1270.17 174.177L1270.4 173.278C1270.88 173.309 1271.67 173.464 1272.65 173.972C1274.54 174.97 1275.02 176.81 1274.03 178.673C1273.06 180.53 1271.19 181.411 1269.39 180.467C1267.38 179.402 1267.78 177.356 1268.38 176.225C1268.51 175.998 1268.62 175.833 1268.69 175.725L1273.02 178.008ZM1269.32 177.079C1268.93 177.781 1268.65 179.047 1269.91 179.715C1271.04 180.312 1272.09 179.533 1272.58 178.803L1269.32 177.079Z" fill="black"/>
<path d="M1258.3 177.964L1266.47 166.141L1267.26 166.69L1259.09 178.513L1258.3 177.964Z" fill="black"/>
<path d="M1259.58 165.502L1259.06 166.086L1257.69 166.152C1254.39 166.272 1252.78 166.459 1251.84 167.504C1251.2 168.212 1250.97 169.179 1252.01 170.104C1252.64 170.667 1253.46 170.816 1254.02 170.825L1253.73 171.758C1252.89 171.735 1251.92 171.428 1251.12 170.707C1249.61 169.377 1249.89 167.775 1250.78 166.772C1251.93 165.48 1253.79 165.269 1256.52 165.153L1257.54 165.13L1257.56 165.107L1254.41 162.32L1255.11 161.531L1259.58 165.502Z" fill="black"/>
<path d="M1251.59 157.882L1249.74 159.625L1252.72 162.787L1252.11 163.36L1244.91 164.42L1243.97 163.424L1248.2 159.442L1247.31 158.487L1248.03 157.804L1248.92 158.759L1250.77 157.016L1251.59 157.882ZM1249.01 160.313L1246.74 162.455C1246.38 162.794 1246.02 163.116 1245.64 163.431L1245.67 163.459C1246.26 163.295 1246.72 163.186 1247.21 163.079L1251.1 162.562L1251.12 162.54L1249.01 160.313Z" fill="black"/>
<path d="M1250.27 155.591L1239.65 159.024L1239.03 158.262L1249.67 154.841L1250.27 155.591Z" fill="black"/>
<path d="M1235.28 152.084L1235.96 151.609L1245.28 150.106L1246.02 151.182L1236.91 152.558L1236.88 152.572L1239.49 156.336L1238.63 156.933L1235.28 152.084Z" fill="black"/>
<path d="M1231.39 145.936C1230.96 145.301 1230.49 144.522 1230.05 143.633C1229.26 142.019 1229.09 140.681 1229.43 139.578C1229.76 138.454 1230.55 137.514 1231.96 136.832C1233.38 136.146 1234.76 136.025 1235.95 136.443C1237.16 136.864 1238.25 137.992 1239.07 139.686C1239.46 140.492 1239.75 141.179 1239.96 141.776L1231.39 145.936ZM1238.55 141.075C1238.46 140.771 1238.27 140.349 1238.06 139.906C1236.89 137.502 1234.92 136.848 1232.56 137.99C1230.49 138.973 1229.76 140.769 1230.91 143.148C1231.2 143.723 1231.47 144.145 1231.68 144.412L1238.55 141.075Z" fill="black"/>
<path d="M1232.78 133.76C1234.37 133.149 1234.66 131.886 1234.23 130.697C1233.92 129.844 1233.58 129.387 1233.23 129.013L1234.01 128.498C1234.35 128.843 1234.82 129.489 1235.21 130.531C1235.94 132.544 1235.05 134.229 1233.08 134.944C1231.11 135.664 1229.13 135.065 1228.44 133.161C1227.67 131.031 1229.35 129.775 1230.54 129.338C1230.79 129.25 1230.99 129.204 1231.11 129.175L1232.78 133.76ZM1230.65 130.581C1229.89 130.841 1228.84 131.596 1229.32 132.933C1229.76 134.13 1231.06 134.254 1231.92 134.057L1230.65 130.581Z" fill="black"/>
<path d="M1232.57 127.193C1232.7 126.773 1232.78 126.064 1232.62 125.451C1232.37 124.564 1231.81 124.276 1231.26 124.421C1230.67 124.58 1230.45 125.019 1230.37 126.007C1230.27 127.336 1229.76 128.083 1228.96 128.303C1227.88 128.604 1226.75 127.972 1226.35 126.531C1226.16 125.856 1226.2 125.205 1226.31 124.773L1227.29 124.831C1227.19 125.141 1227.11 125.688 1227.28 126.309C1227.47 127.027 1228 127.317 1228.5 127.176C1229.05 127.022 1229.19 126.554 1229.28 125.583C1229.4 124.296 1229.82 123.545 1230.82 123.266C1232 122.942 1233.09 123.62 1233.52 125.217C1233.72 125.952 1233.73 126.677 1233.59 127.227L1232.57 127.193Z" fill="black"/>
<path d="M1223.37 121.815C1223.78 121.709 1224.19 121.951 1224.29 122.442C1224.39 122.875 1224.12 123.26 1223.7 123.35C1223.27 123.444 1222.86 123.194 1222.76 122.74C1222.65 122.27 1222.93 121.902 1223.37 121.815ZM1232.37 121.296L1225.57 122.765L1225.3 121.527L1232.1 120.058L1232.37 121.296Z" fill="black"/>
<path d="M1223.93 113.252C1224.44 113.205 1225 113.158 1225.83 113.031L1229.83 112.447C1231.42 112.219 1232.42 112.391 1233.12 112.971C1233.85 113.551 1234.19 114.484 1234.31 115.352C1234.43 116.178 1234.37 117.114 1234.08 117.724L1233.08 117.548C1233.3 117.053 1233.45 116.301 1233.32 115.446C1233.14 114.168 1232.33 113.328 1230.6 113.578L1229.83 113.689L1229.84 113.718C1230.54 114.011 1231.15 114.674 1231.31 115.747C1231.55 117.455 1230.28 118.883 1228.38 119.165C1226.05 119.503 1224.5 118.174 1224.27 116.599C1224.1 115.402 1224.62 114.658 1225.16 114.274L1225.15 114.244L1224.11 114.337L1223.93 113.252ZM1226.84 114.151C1226.63 114.185 1226.44 114.227 1226.29 114.305C1225.6 114.638 1225.09 115.338 1225.22 116.25C1225.39 117.446 1226.53 118.151 1228.12 117.918C1229.47 117.725 1230.5 116.878 1230.3 115.52C1230.19 114.753 1229.61 114.127 1228.77 113.971C1228.54 113.933 1228.29 113.936 1228.08 113.97L1226.84 114.151Z" fill="black"/>
<path d="M1225.59 110.77C1224.87 110.808 1224.28 110.848 1223.71 110.912L1223.66 109.79L1224.81 109.661L1224.8 109.64C1224.12 109.324 1223.45 108.553 1223.39 107.402C1223.35 106.442 1223.85 104.922 1226.23 104.809L1230.38 104.616L1230.44 105.879L1226.43 106.068C1225.31 106.119 1224.39 106.582 1224.45 107.776C1224.48 108.607 1225.11 109.231 1225.82 109.407C1225.98 109.458 1226.2 109.474 1226.42 109.462L1230.6 109.262L1230.65 110.526L1225.59 110.77Z" fill="black"/>
<path d="M1221.56 101.143C1221.99 101.138 1222.33 101.461 1222.32 101.964C1222.31 102.411 1221.96 102.716 1221.52 102.712C1221.08 102.705 1220.74 102.368 1220.75 101.908C1220.76 101.427 1221.11 101.127 1221.56 101.143ZM1230.44 102.744L1223.48 102.59L1223.5 101.327L1230.46 101.48L1230.44 102.744Z" fill="black"/>
<path d="M1225.45 99.0974C1224.73 99.0328 1224.15 98.995 1223.57 98.9869L1223.66 97.8689L1224.81 97.903L1224.82 97.8768C1224.19 97.4765 1223.62 96.6088 1223.73 95.4646C1223.81 94.5059 1224.52 93.0677 1226.89 93.2818L1231.03 93.6605L1230.92 94.9213L1226.92 94.5598C1225.8 94.4637 1224.84 94.7879 1224.73 95.9796C1224.66 96.8087 1225.18 97.5114 1225.86 97.7842C1226.01 97.8525 1226.23 97.9038 1226.44 97.9254L1230.61 98.2981L1230.49 99.5588L1225.45 99.0974Z" fill="black"/>
<path d="M1225.57 84.6395C1226.06 84.7645 1226.6 84.8952 1227.43 85.0556L1231.4 85.8096C1232.97 86.1105 1233.87 86.5975 1234.34 87.3766C1234.84 88.1626 1234.85 89.1624 1234.68 90.0228C1234.53 90.8438 1234.16 91.7074 1233.69 92.1911L1232.8 91.6993C1233.16 91.2993 1233.56 90.6401 1233.71 89.7976C1233.96 88.5292 1233.47 87.472 1231.75 87.1372L1230.99 86.9896L1230.98 87.0158C1231.55 87.5186 1231.91 88.3468 1231.71 89.4071C1231.38 91.1016 1229.71 92.0435 1227.82 91.6846C1225.5 91.2395 1224.48 89.4845 1224.77 87.9126C1224.99 86.7227 1225.73 86.1927 1226.36 86.0092L1226.36 85.983L1225.34 85.7307L1225.57 84.6395ZM1228.02 86.4417C1227.81 86.3986 1227.63 86.384 1227.45 86.4073C1226.68 86.4964 1225.97 86.9941 1225.81 87.8973C1225.59 89.0872 1226.43 90.119 1228.01 90.4234C1229.35 90.6778 1230.6 90.2122 1230.85 88.8699C1231 88.1095 1230.65 87.3276 1229.91 86.9041C1229.71 86.7956 1229.48 86.7193 1229.26 86.6811L1228.02 86.4417Z" fill="black"/>
<path d="M1226.02 75.7786L1227.92 76.3991L1228.48 74.6773L1229.4 74.9788L1228.83 76.7005L1232.4 77.8736C1233.22 78.1422 1233.76 78.0625 1233.98 77.3922C1234.08 77.0766 1234.12 76.8339 1234.12 76.6663L1235.05 76.9071C1235.07 77.1721 1235.01 77.5576 1234.86 78.0219C1234.68 78.5827 1234.35 78.9718 1233.93 79.1555C1233.47 79.3811 1232.84 79.3114 1232.06 79.0533L1228.45 77.8745L1228.11 78.8997L1227.19 78.5982L1227.53 77.5731L1225.94 77.0498L1226.02 75.7786Z" fill="black"/>
<path d="M1226.07 71.7727L1226.56 70.6054L1230.57 72.2758L1230.58 72.2497C1230.32 71.9215 1230.15 71.5109 1230.11 71.0711C1230.05 70.641 1230.09 70.1748 1230.28 69.734C1230.63 68.8752 1231.74 67.7134 1233.96 68.6369L1237.78 70.2285L1237.29 71.3958L1233.6 69.8607C1232.57 69.429 1231.53 69.4526 1231.08 70.5449C1230.76 71.3025 1231.04 72.1144 1231.59 72.5971C1231.72 72.7308 1231.89 72.8109 1232.11 72.9134L1235.99 74.5273L1235.5 75.6947L1226.07 71.7727Z" fill="black"/>
<path d="M1236.03 66.4213C1237.56 67.2016 1238.69 66.5581 1239.29 65.4402C1239.71 64.6418 1239.83 64.0848 1239.87 63.5758L1240.77 63.8095C1240.74 64.2924 1240.58 65.079 1240.06 66.055C1239.06 67.9487 1237.21 68.4206 1235.36 67.4325C1233.5 66.4445 1232.62 64.5865 1233.57 62.7858C1234.63 60.7765 1236.68 61.1776 1237.81 61.7754C1238.04 61.8992 1238.2 62.0138 1238.32 62.0929L1236.03 66.4213ZM1236.98 62.7231C1236.27 62.3315 1235.01 62.0554 1234.34 63.3067C1233.74 64.4377 1234.52 65.4817 1235.24 65.9838L1236.98 62.7231Z" fill="black"/>
<path d="M1244.38 58.6928L1239.47 55.2513L1238.91 56.0492L1238.13 55.4951L1238.68 54.6972L1238.41 54.5118C1237.61 53.9541 1237 53.2649 1236.88 52.4605C1236.78 51.8094 1236.99 51.1831 1237.33 50.6989C1237.58 50.3316 1237.89 50.077 1238.11 49.9345L1238.82 50.6352C1238.64 50.7359 1238.44 50.9116 1238.23 51.2073C1237.61 52.0863 1238.23 52.8564 1239.1 53.4695L1239.41 53.6786L1240.35 52.2953L1241.14 52.8494L1240.17 54.2257L1245.08 57.6672L1244.38 58.6928Z" fill="black"/>
<path d="M1248.81 49.8118C1249.37 50.2634 1249.86 50.6548 1250.32 50.9666L1249.61 51.8294L1248.69 51.1677L1248.67 51.1904C1248.89 51.8041 1248.99 52.8285 1248.21 53.7902C1247.51 54.6351 1246.22 55.271 1244.33 53.7184L1241.18 51.1417L1241.98 50.162L1244.96 52.611C1245.99 53.447 1246.93 53.6996 1247.66 52.8142C1248.2 52.1591 1248.11 51.3272 1247.82 50.7947C1247.72 50.6204 1247.57 50.4403 1247.39 50.3008L1244.09 47.5917L1244.89 46.612L1248.81 49.8118Z" fill="black"/>
<path d="M1246.17 42.331L1247.61 43.7085L1248.86 42.4011L1249.56 43.0721L1248.31 44.3795L1251.03 46.9736C1251.65 47.5676 1252.17 47.7294 1252.66 47.2231C1252.89 46.9819 1253.03 46.7804 1253.11 46.6338L1253.84 47.2428C1253.75 47.4846 1253.53 47.8196 1253.19 48.1707C1252.78 48.5946 1252.32 48.8071 1251.87 48.7913C1251.35 48.7924 1250.81 48.4595 1250.22 47.8938L1247.48 45.2714L1246.71 46.0476L1246.01 45.3849L1246.76 44.6016L1245.55 43.4502L1246.17 42.331Z" fill="black"/>
<path d="M1257.82 41.0341C1258.3 41.5682 1258.74 42.0359 1259.13 42.4158L1258.3 43.1614L1257.49 42.3684L1257.47 42.3911C1257.59 43.0278 1257.54 44.066 1256.62 44.8857C1255.8 45.6134 1254.43 46.0392 1252.8 44.2131L1250.09 41.1851L1251.03 40.3427L1253.6 43.2073C1254.48 44.1938 1255.38 44.5821 1256.24 43.8221C1256.87 43.2557 1256.91 42.4245 1256.71 41.8523C1256.64 41.6636 1256.52 41.4644 1256.37 41.2891L1253.51 38.1107L1254.46 37.2683L1257.82 41.0341Z" fill="black"/>
<path d="M1257.68 37.474C1257.19 36.8211 1256.74 36.2678 1256.32 35.7823L1257.2 35.1066L1258.07 36.1668L1258.11 36.1298C1257.8 35.1955 1258.05 34.2658 1258.72 33.7444C1258.83 33.656 1258.92 33.608 1259.03 33.5623L1259.76 34.5067C1259.64 34.5655 1259.53 34.6278 1259.39 34.7307C1258.68 35.2712 1258.58 36.1919 1259.02 37.0543C1259.1 37.2085 1259.2 37.3828 1259.34 37.5593L1261.59 40.5074L1260.6 41.2667L1257.68 37.474Z" fill="black"/>
<path d="M1262.92 35.5151C1263.88 36.9304 1265.19 36.9163 1266.24 36.222C1266.99 35.7217 1267.36 35.2956 1267.64 34.8663L1268.32 35.5041C1268.06 35.919 1267.55 36.5253 1266.63 37.1345C1264.84 38.3112 1263 37.8379 1261.84 36.0906C1260.69 34.3349 1260.82 32.2787 1262.51 31.1595C1264.41 29.9074 1266.01 31.2414 1266.71 32.3154C1266.86 32.53 1266.94 32.7139 1267 32.8349L1262.92 35.5151ZM1265.51 32.7143C1265.07 32.0362 1264.1 31.1798 1262.91 31.9638C1261.85 32.6676 1262.03 33.958 1262.43 34.7457L1265.51 32.7143Z" fill="black"/>
</svg>


</a>

<!-- ESPAÑOL FIN -->
<a v-if="idioma == 'Inglés'" href="mailto:please@vicdesing.me">
    <svg width="1714" height="365" viewBox="0 0 1714 365" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 360.658V126.289H35.3614V330.218H141.56V360.658H0Z" fill="#333333"/>
<path d="M181.9 360.658V126.289H328.839V156.729H217.261V228.139H321.171V258.465H217.261V330.218H330.212V360.658H181.9Z" fill="#333333"/>
<path d="M364.515 156.729V126.289H545.899V156.729H472.773V360.658H437.526V156.729H364.515Z" fill="#333333"/>
<path d="M612.646 126.289V148.261C612.646 154.746 611.425 161.574 608.983 168.745C606.618 175.841 603.262 182.669 598.913 189.23C594.564 195.791 589.453 201.437 583.578 206.167L566.641 194.036C571.143 187.475 575.033 180.494 578.314 173.094C581.671 165.694 583.349 157.53 583.349 148.604V126.289H612.646Z" fill="#333333"/>
<path d="M778.009 187.857C776.788 177.023 771.753 168.631 762.903 162.68C754.053 156.653 742.914 153.64 729.487 153.64C719.874 153.64 711.558 155.165 704.54 158.217C697.521 161.192 692.066 165.312 688.175 170.576C684.36 175.764 682.453 181.677 682.453 188.314C682.453 193.884 683.75 198.69 686.344 202.734C689.014 206.777 692.485 210.172 696.758 212.919C701.106 215.589 705.76 217.839 710.719 219.67C715.678 221.425 720.446 222.875 725.024 224.019L747.912 229.97C755.388 231.801 763.056 234.28 770.914 237.408C778.772 240.536 786.058 244.656 792.771 249.768C799.485 254.879 804.902 261.212 809.022 268.764C813.218 276.317 815.316 285.358 815.316 295.886C815.316 309.161 811.883 320.948 805.016 331.248C798.226 341.547 788.346 349.672 775.377 355.623C762.483 361.574 746.882 364.549 728.572 364.549C711.024 364.549 695.842 361.765 683.025 356.195C670.208 350.626 660.176 342.73 652.928 332.507C645.68 322.207 641.675 310 640.912 295.886H676.388C677.074 304.355 679.821 311.412 684.627 317.057C689.51 322.627 695.728 326.785 703.281 329.531C710.91 332.201 719.264 333.537 728.343 333.537C738.337 333.537 747.225 331.973 755.007 328.845C762.865 325.64 769.045 321.215 773.546 315.57C778.047 309.848 780.298 303.172 780.298 295.543C780.298 288.6 778.314 282.917 774.347 278.492C770.456 274.067 765.154 270.405 758.44 267.506C751.803 264.607 744.288 262.051 735.896 259.838L708.201 252.285C689.434 247.174 674.557 239.659 663.571 229.741C652.661 219.823 647.206 206.701 647.206 190.374C647.206 176.87 650.868 165.083 658.192 155.013C665.516 144.942 675.434 137.122 687.946 131.553C700.458 125.907 714.572 123.084 730.288 123.084C746.157 123.084 760.156 125.869 772.287 131.438C784.494 137.008 794.107 144.675 801.125 154.441C808.144 164.13 811.806 175.268 812.112 187.857H778.009Z" fill="#333333"/>
<path d="M931.299 156.729V126.289H1112.68V156.729H1039.56V360.658H1004.31V156.729H931.299Z" fill="#333333"/>
<path d="M1144.87 360.658H1107.33L1191.67 126.289H1232.53L1316.87 360.658H1279.33L1213.07 168.86H1211.24L1144.87 360.658ZM1151.16 268.879H1272.93V298.633H1151.16V268.879Z" fill="#333333"/>
<path d="M1350.72 360.658V126.289H1386.08V330.218H1492.28V360.658H1350.72Z" fill="#333333"/>
<path d="M1532.62 360.658V126.289H1567.98V238.209H1570.84L1669.14 126.289H1713.66L1619.13 231.801L1714 360.658H1671.43L1595.67 255.947L1567.98 287.761V360.658H1532.62Z" fill="#333333"/>
<circle cx="1397.66" cy="103.663" r="102.472" transform="rotate(-30 1397.66 103.663)" fill="#F0F0F0" stroke="black"/>
<circle cx="1397.86" cy="103.715" r="64.8123" transform="rotate(-30 1397.86 103.715)" stroke="#333333"/>
<circle cx="1397.86" cy="103.715" r="59.5346" transform="rotate(-30 1397.86 103.715)" fill="#F0F0F0" stroke="#F0F0F0"/>
<ellipse cx="1369.44" cy="98.031" rx="10.5555" ry="23.7499" transform="rotate(-30 1369.44 98.031)" fill="#333333"/>
<ellipse cx="1408.86" cy="75.271" rx="11.2153" ry="23.7499" transform="rotate(-30 1408.86 75.271)" fill="#333333"/>
<path d="M1439.89 80.2061C1453.01 102.925 1444.97 132.123 1421.94 145.422C1398.9 158.721 1369.6 151.085 1356.48 128.366" stroke="#333333"/>
<path d="M1359.33 21.9808L1365.58 34.9183L1364.72 35.3395L1358.47 22.402L1359.33 21.9808Z" fill="black"/>
<path d="M1369.54 29.257L1369.29 28.5162L1369.92 27.2805C1371.44 24.3549 1372.07 22.8612 1371.62 21.5268C1371.31 20.6305 1370.59 19.9525 1369.27 20.4006C1368.47 20.6765 1367.94 21.3137 1367.65 21.7906L1366.99 21.079C1367.42 20.353 1368.16 19.6732 1369.18 19.3239C1371.09 18.6686 1372.34 19.7057 1372.77 20.9724C1373.33 22.6095 1372.59 24.3284 1371.35 26.7605L1370.87 27.6567L1370.88 27.6864L1374.85 26.3228L1375.2 27.32L1369.54 29.257Z" fill="black"/>
<path d="M1380.13 26.0398L1379.53 23.5683L1375.31 24.5989L1375.1 23.7865L1377.74 16.9979L1379.07 16.6733L1380.44 22.3194L1381.71 22.007L1381.95 22.9678L1380.68 23.2802L1381.29 25.7517L1380.13 26.0398ZM1379.3 22.6027L1378.56 19.5683C1378.44 19.0944 1378.34 18.611 1378.25 18.1311L1378.21 18.1419C1378.06 18.7395 1377.94 19.1846 1377.79 19.6704L1376.32 23.3055L1376.33 23.3352L1379.3 22.6027Z" fill="black"/>
<path d="M1382.78 26.0272L1385.03 15.0921L1385.99 14.9357L1383.72 25.872L1382.78 26.0272Z" fill="black"/>
<path d="M1393.22 14.7202L1393.29 15.5533L1390 24.3958L1388.7 24.517L1392 15.9229L1392 15.8932L1387.44 16.2999L1387.35 15.2576L1393.22 14.7202Z" fill="black"/>
<path d="M1400.52 14.3727C1401.28 14.3051 1402.19 14.2891 1403.18 14.3501C1404.98 14.4741 1406.22 14.975 1407.02 15.8204C1407.83 16.6692 1408.25 17.8206 1408.15 19.3857C1408.04 20.9639 1407.46 22.2267 1406.5 23.049C1405.54 23.8927 1404.01 24.2764 1402.13 24.1528C1401.24 24.0901 1400.5 23.9971 1399.88 23.8846L1400.52 14.3727ZM1401.18 23.0073C1401.49 23.0832 1401.95 23.1287 1402.44 23.1682C1405.11 23.3512 1406.65 21.9533 1406.83 19.3488C1407 17.0712 1405.81 15.5321 1403.17 15.3512C1402.53 15.3042 1402.03 15.3337 1401.7 15.384L1401.18 23.0073Z" fill="black"/>
<path d="M1410.41 21.6205C1410.15 23.3111 1411.11 24.1938 1412.35 24.407C1413.25 24.5552 1413.81 24.4956 1414.32 24.3792L1414.38 25.3075C1413.9 25.4261 1413.11 25.5295 1412.03 25.337C1409.91 24.9743 1408.89 23.3738 1409.25 21.2951C1409.61 19.2295 1411.11 17.7997 1413.1 18.1523C1415.34 18.5418 1415.59 20.6094 1415.38 21.8718C1415.34 22.1276 1415.28 22.3205 1415.24 22.4431L1410.41 21.6205ZM1414.22 21.3574C1414.37 20.5625 1414.25 19.2731 1412.84 19.0356C1411.57 18.8237 1410.83 19.8863 1410.57 20.7304L1414.22 21.3574Z" fill="black"/>
<path d="M1416.2 24.7C1416.49 25.0257 1417.08 25.4416 1417.69 25.5982C1418.58 25.8237 1419.12 25.4824 1419.25 24.929C1419.4 24.3446 1419.13 23.9356 1418.31 23.3709C1417.21 22.6339 1416.8 21.8166 1417 21.0143C1417.28 19.924 1418.38 19.2569 1419.83 19.625C1420.51 19.7943 1421.06 20.1438 1421.39 20.4634L1420.85 21.2813C1420.63 21.0457 1420.19 20.6981 1419.57 20.538C1418.85 20.3581 1418.34 20.6719 1418.21 21.1695C1418.07 21.7277 1418.41 22.0805 1419.2 22.6346C1420.26 23.3741 1420.7 24.1117 1420.46 25.1186C1420.15 26.3006 1419.02 26.9096 1417.43 26.5029C1416.69 26.3196 1416.05 25.9586 1415.64 25.5621L1416.2 24.7Z" fill="black"/>
<path d="M1421.42 27.4398L1423.49 20.7964L1424.7 21.175L1422.62 27.8185L1421.42 27.4398ZM1425.42 19.3539C1425.3 19.7729 1424.9 20.0078 1424.41 19.8566C1423.99 19.7229 1423.79 19.297 1423.92 18.8898C1424.05 18.4695 1424.47 18.2299 1424.92 18.3705C1425.37 18.5064 1425.55 18.9253 1425.42 19.3539Z" fill="black"/>
<path d="M1432.59 24.0682C1432.38 24.5316 1432.15 25.0391 1431.84 25.8264L1430.38 29.5947C1429.8 31.0811 1429.15 31.8758 1428.31 32.197C1427.44 32.5409 1426.46 32.3764 1425.64 32.0519C1424.87 31.7462 1424.08 31.2266 1423.7 30.6743L1424.34 29.8927C1424.67 30.324 1425.24 30.8351 1426.05 31.143C1427.25 31.612 1428.38 31.3252 1429.02 29.6935L1429.3 28.9682L1429.27 28.9612C1428.68 29.4263 1427.79 29.6383 1426.79 29.2433C1425.18 28.6227 1424.56 26.8005 1425.26 25.0021C1426.11 22.8035 1428.03 22.1173 1429.52 22.698C1430.64 23.1329 1431.03 23.9598 1431.1 24.617L1431.13 24.624L1431.56 23.6662L1432.59 24.0682ZM1430.37 26.1552C1430.45 25.9575 1430.51 25.7777 1430.52 25.5969C1430.57 24.8251 1430.21 24.0479 1429.35 23.7129C1428.23 23.278 1427.05 23.911 1426.47 25.4104C1425.97 26.6812 1426.2 27.9904 1427.47 28.4936C1428.2 28.7722 1429.04 28.5758 1429.58 27.9207C1429.72 27.7488 1429.84 27.5272 1429.92 27.3247L1430.37 26.1552Z" fill="black"/>
<path d="M1433.92 26.7326C1434.24 26.0903 1434.49 25.5601 1434.71 25.0229L1435.71 25.5297L1435.26 26.5875L1435.29 26.5945C1435.89 26.1591 1436.91 25.9477 1437.93 26.4615C1438.79 26.8952 1439.87 28.0888 1438.79 30.2147L1436.92 33.9218L1435.79 33.3454L1437.59 29.767C1438.1 28.7661 1438.15 27.741 1437.09 27.2036C1436.34 26.8276 1435.5 27.0585 1434.99 27.5957C1434.87 27.7104 1434.74 27.8941 1434.65 28.08L1432.75 31.8312L1431.62 31.2548L1433.92 26.7326Z" fill="black"/>
<path d="M1438.53 34.9074L1442.09 28.9302L1443.17 29.5781L1439.61 35.5553L1438.53 34.9074ZM1444.3 27.9776C1444.09 28.3506 1443.64 28.4893 1443.21 28.2321C1442.82 28.002 1442.73 27.5434 1442.95 27.1739C1443.18 26.7865 1443.64 26.6643 1444.03 26.9028C1444.45 27.1399 1444.53 27.5901 1444.3 27.9776Z" fill="black"/>
<path d="M1444.17 32.3571C1444.58 31.7655 1444.9 31.2695 1445.2 30.7796L1446.12 31.4198L1445.53 32.4045L1445.55 32.4247C1446.21 32.0757 1447.24 32.001 1448.19 32.6614C1448.98 33.2107 1449.88 34.5395 1448.52 36.4978L1446.15 39.9122L1445.12 39.1928L1447.41 35.8904C1448.05 34.9674 1448.23 33.9643 1447.25 33.2802C1446.56 32.8018 1445.69 32.9269 1445.12 33.3825C1444.98 33.4806 1444.84 33.6441 1444.71 33.8147L1442.33 37.2601L1441.29 36.5407L1444.17 32.3571Z" fill="black"/>
<path d="M1456.67 39.5944C1456.33 39.9585 1455.94 40.3728 1455.39 41.0196L1452.78 44.1011C1451.74 45.3184 1450.87 45.8562 1449.96 45.8804C1449.04 45.9273 1448.16 45.4473 1447.49 44.882C1446.86 44.3403 1446.29 43.5974 1446.1 42.9431L1446.96 42.4102C1447.13 42.9243 1447.51 43.5884 1448.17 44.1502C1449.16 44.984 1450.32 45.0906 1451.45 43.7468L1451.96 43.1584L1451.93 43.1383C1451.22 43.3828 1450.31 43.287 1449.49 42.5892C1448.17 41.4703 1448.18 39.5501 1449.43 38.0881C1450.96 36.2872 1452.99 36.2639 1454.21 37.2988C1455.13 38.0854 1455.23 38.9897 1455.08 39.6252L1455.11 39.6453L1455.83 38.8848L1456.67 39.5944ZM1453.9 40.8432C1454.03 40.6762 1454.14 40.5235 1454.21 40.3603C1454.52 39.6551 1454.44 38.7923 1453.73 38.195C1452.81 37.4085 1451.49 37.6296 1450.45 38.86C1449.57 39.9006 1449.35 41.2189 1450.39 42.1012C1450.98 42.6027 1451.84 42.6845 1452.57 42.2497C1452.77 42.1263 1452.95 41.965 1453.09 41.798L1453.9 40.8432Z" fill="black"/>
<path d="M1464.16 44.3712L1462.67 45.7142L1463.89 47.0582L1463.18 47.7022L1461.96 46.3582L1459.17 48.8745C1458.53 49.4539 1458.33 49.9602 1458.81 50.4812C1459.03 50.7298 1459.23 50.8812 1459.36 50.9673L1458.7 51.6516C1458.45 51.5373 1458.15 51.2938 1457.82 50.9314C1457.42 50.4957 1457.24 50.0126 1457.29 49.5629C1457.33 49.0456 1457.7 48.5291 1458.31 47.9902L1461.12 45.4464L1460.4 44.6483L1461.11 44.0043L1461.84 44.8024L1463.08 43.6888L1464.16 44.3712Z" fill="black"/>
<path d="M1467.63 46.4176L1468.4 47.4236L1464.96 50.0736L1464.97 50.0985C1465.38 50.0396 1465.82 50.0947 1466.23 50.2676C1466.63 50.4371 1467.01 50.7017 1467.31 51.0749C1467.88 51.8178 1468.33 53.3495 1466.43 54.812L1463.15 57.3365L1462.38 56.3305L1465.55 53.8945C1466.44 53.2141 1466.93 52.3 1466.21 51.3556C1465.71 50.7027 1464.85 50.5437 1464.17 50.7821C1463.99 50.8317 1463.84 50.931 1463.64 51.0805L1460.31 53.642L1459.54 52.636L1467.63 46.4176Z" fill="black"/>
<path d="M1467.32 57.717C1465.89 58.6532 1465.88 59.9551 1466.56 61.0269C1467.04 61.788 1467.47 62.1701 1467.89 62.4583L1467.24 63.1199C1466.83 62.8483 1466.23 62.3302 1465.64 61.3995C1464.5 59.5915 1465 57.7536 1466.78 56.6375C1468.55 55.5132 1470.61 55.6777 1471.69 57.3861C1472.91 59.3055 1471.54 60.8822 1470.47 61.5695C1470.25 61.7072 1470.06 61.7912 1469.94 61.85L1467.32 57.717ZM1470.07 60.3692C1470.76 59.95 1471.63 58.9914 1470.87 57.7833C1470.19 56.7032 1468.89 56.8588 1468.09 57.2416L1470.07 60.3692Z" fill="black"/>
<path d="M1469.92 68.7913L1475.34 66.2157L1474.92 65.3307L1475.79 64.9178L1476.21 65.8029L1476.51 65.6648C1477.39 65.2424 1478.29 65.0553 1479.05 65.3491C1479.66 65.5782 1480.11 66.0756 1480.36 66.6059C1480.55 67.007 1480.63 67.4013 1480.64 67.6628L1479.68 67.9263C1479.68 67.7158 1479.63 67.4569 1479.48 67.1354C1479.02 66.1625 1478.04 66.3154 1477.08 66.781L1476.74 66.943L1477.47 68.4628L1476.6 68.8757L1475.88 67.3559L1470.46 69.9315L1469.92 68.7913Z" fill="black"/>
<path d="M1475.45 77.031C1474.79 77.2943 1474.2 77.5312 1473.71 77.7711L1473.3 76.7219L1474.34 76.2477L1474.32 76.2228C1473.68 76.1199 1472.74 75.6996 1472.28 74.546C1471.88 73.5265 1471.97 72.091 1474.25 71.207L1478.04 69.7385L1478.49 70.9135L1474.91 72.3098C1473.68 72.7873 1472.99 73.4859 1473.41 74.56C1473.71 75.3445 1474.48 75.6895 1475.08 75.6963C1475.28 75.693 1475.51 75.6575 1475.73 75.5674L1479.7 74.0231L1480.16 75.1981L1475.45 77.031Z" fill="black"/>
<path d="M1483.27 78.43L1481.35 79.0045L1481.88 80.738L1480.95 81.0168L1480.43 79.2833L1476.83 80.3622C1476 80.6096 1475.61 80.9894 1475.81 81.6603C1475.91 81.9809 1476.02 82.1932 1476.11 82.3426L1475.21 82.6712C1475.05 82.4662 1474.87 82.1163 1474.72 81.6485C1474.55 81.0833 1474.59 80.5743 1474.84 80.1856C1475.09 79.7279 1475.65 79.4282 1476.44 79.1964L1480.08 78.1018L1479.77 77.0641L1480.69 76.7853L1481 77.8231L1482.6 77.3452L1483.27 78.43Z" fill="black"/>
<path d="M1478.65 89.2285C1477.95 89.3778 1477.34 89.5351 1476.81 89.6956L1476.57 88.5933L1477.66 88.2829L1477.66 88.2532C1477.04 88.0455 1476.17 87.4882 1475.9 86.2803C1475.67 85.2077 1475.97 83.8022 1478.37 83.2851L1482.35 82.4149L1482.62 83.6525L1478.85 84.4754C1477.56 84.7594 1476.78 85.3431 1477.02 86.4619C1477.2 87.2886 1477.91 87.7444 1478.5 87.8464C1478.69 87.8777 1478.93 87.8718 1479.16 87.8197L1483.33 86.9035L1483.6 88.1411L1478.65 89.2285Z" fill="black"/>
<path d="M1481.81 90.8382C1482.62 90.7252 1483.31 90.6104 1483.95 90.4863L1484.1 91.5854L1482.75 91.8208L1482.76 91.8802C1483.73 92.0626 1484.42 92.7384 1484.54 93.5772C1484.56 93.7222 1484.56 93.8209 1484.55 93.9374L1483.37 94.0984C1483.38 93.9688 1483.38 93.8404 1483.35 93.6657C1483.23 92.7841 1482.48 92.2453 1481.51 92.2044C1481.33 92.1981 1481.13 92.2013 1480.92 92.2273L1477.24 92.7359L1477.06 91.4966L1481.81 90.8382Z" fill="black"/>
<path d="M1480.94 96.3315C1479.24 96.4722 1478.6 97.614 1478.69 98.8716C1478.75 99.7785 1478.94 100.308 1479.17 100.77L1478.28 101.043C1478.05 100.61 1477.77 99.8672 1477.69 98.7595C1477.55 96.6164 1478.87 95.2576 1480.96 95.1103C1483.06 94.9631 1484.79 96.1003 1484.92 98.115C1485.07 100.386 1483.12 101.118 1481.85 101.199C1481.59 101.214 1481.38 101.201 1481.26 101.195L1480.94 96.3315ZM1482.09 99.9728C1482.89 99.9288 1484.12 99.5072 1484.03 98.0916C1483.94 96.8127 1482.72 96.3319 1481.85 96.2941L1482.09 99.9728Z" fill="black"/>
<path d="M1488.64 108.877L1474.27 108.137L1474.33 107.177L1488.69 107.916L1488.64 108.877Z" fill="black"/>
<path d="M1476.66 118.572L1477.41 118.746L1478.13 119.927C1479.81 122.763 1480.75 124.081 1482.11 124.411C1483.03 124.622 1483.99 124.367 1484.3 123.013C1484.49 122.186 1484.23 121.407 1483.97 120.903L1484.93 120.712C1485.32 121.462 1485.5 122.451 1485.27 123.504C1484.82 125.468 1483.29 125.99 1481.98 125.69C1480.3 125.302 1479.22 123.777 1477.81 121.435L1477.3 120.556L1477.28 120.549L1476.34 124.641L1475.32 124.402L1476.66 118.572Z" fill="black"/>
<path d="M1473.81 129.247L1476.23 130.029L1477.57 125.903L1478.36 126.165L1482.75 131.965L1482.33 133.262L1476.8 131.472L1476.4 132.712L1475.46 132.403L1475.86 131.164L1473.44 130.381L1473.81 129.247ZM1477.17 130.337L1480.14 131.302C1480.61 131.454 1481.07 131.62 1481.53 131.798L1481.54 131.759C1481.11 131.321 1480.79 130.97 1480.47 130.595L1478.15 127.44L1478.12 127.433L1477.17 130.337Z" fill="black"/>
<path d="M1472.43 131.528L1480.56 139.187L1480.18 140.09L1472.06 132.418L1472.43 131.528Z" fill="black"/>
<path d="M1476.56 146.354L1475.81 145.978L1470.01 138.536L1470.6 137.367L1476.19 144.689L1476.21 144.696L1478.25 140.605L1479.19 141.073L1476.56 146.354Z" fill="black"/>
<path d="M1473.03 152.728C1472.69 153.414 1472.23 154.202 1471.65 155.01C1470.61 156.477 1469.52 157.267 1468.39 157.503C1467.24 157.744 1466.04 157.498 1464.76 156.595C1463.48 155.676 1462.71 154.529 1462.51 153.269C1462.3 152.006 1462.78 150.505 1463.87 148.966C1464.39 148.244 1464.86 147.653 1465.29 147.185L1473.03 152.728ZM1465.34 148.771C1465.1 148.995 1464.83 149.364 1464.55 149.759C1462.99 151.939 1463.37 153.982 1465.5 155.508C1467.35 156.853 1469.28 156.642 1470.82 154.484C1471.19 153.959 1471.43 153.533 1471.57 153.211L1465.34 148.771Z" fill="black"/>
<path d="M1461.68 157.348C1460.37 156.241 1459.12 156.586 1458.29 157.534C1457.68 158.214 1457.45 158.727 1457.28 159.222L1456.47 158.786C1456.6 158.318 1456.94 157.593 1457.67 156.759C1459.09 155.154 1461 155.13 1462.57 156.513C1464.14 157.895 1464.56 159.923 1463.23 161.446C1461.72 163.147 1459.82 162.282 1458.87 161.429C1458.67 161.256 1458.55 161.104 1458.45 161.003L1461.68 157.348ZM1459.9 160.731C1460.49 161.275 1461.66 161.841 1462.61 160.775C1463.45 159.809 1462.94 158.612 1462.35 157.956L1459.9 160.731Z" fill="black"/>
<path d="M1456 160.668C1455.57 160.755 1454.91 161.022 1454.45 161.466C1453.79 162.103 1453.8 162.737 1454.19 163.152C1454.61 163.586 1455.1 163.578 1456.01 163.176C1457.22 162.631 1458.13 162.718 1458.7 163.315C1459.48 164.127 1459.47 165.416 1458.39 166.451C1457.89 166.941 1457.3 167.226 1456.86 167.333L1456.45 166.452C1456.77 166.39 1457.29 166.2 1457.76 165.755C1458.3 165.233 1458.29 164.636 1457.94 164.267C1457.54 163.853 1457.06 163.955 1456.16 164.339C1454.97 164.849 1454.11 164.841 1453.39 164.102C1452.54 163.221 1452.62 161.945 1453.82 160.791C1454.36 160.264 1455 159.908 1455.55 159.776L1456 160.668Z" fill="black"/>
<path d="M1450.94 163.635L1455.5 168.897L1454.54 169.728L1449.98 164.466L1450.94 163.635ZM1455.7 171.303C1455.41 170.985 1455.43 170.52 1455.8 170.193C1456.14 169.898 1456.6 169.952 1456.89 170.287C1457.18 170.625 1457.16 171.104 1456.8 171.408C1456.45 171.7 1456 171.641 1455.7 171.303Z" fill="black"/>
<path d="M1447.9 174.91C1447.63 174.493 1447.31 174.022 1446.8 173.344L1444.38 170.114C1443.42 168.84 1443.09 167.86 1443.27 166.981C1443.43 166.07 1444.09 165.322 1444.79 164.794C1445.46 164.299 1446.32 163.904 1446.99 163.868L1447.32 164.832C1446.78 164.877 1446.04 165.101 1445.36 165.619C1444.32 166.394 1443.97 167.506 1445.02 168.912L1445.49 169.528L1445.51 169.513C1445.43 168.762 1445.72 167.899 1446.59 167.254C1447.97 166.222 1449.84 166.651 1451 168.197C1452.41 170.083 1451.99 172.075 1450.7 173.037C1449.74 173.76 1448.83 173.651 1448.24 173.373L1448.22 173.388L1448.8 174.259L1447.9 174.91ZM1447.3 171.927C1447.43 172.095 1447.55 172.242 1447.7 172.345C1448.33 172.793 1449.18 172.909 1449.92 172.358C1450.89 171.634 1450.97 170.302 1450.01 169.012C1449.18 167.914 1447.95 167.422 1446.86 168.239C1446.24 168.705 1445.97 169.516 1446.23 170.329C1446.3 170.544 1446.42 170.76 1446.55 170.928L1447.3 171.927Z" fill="black"/>
<path d="M1444.94 174.637C1445.32 175.25 1445.63 175.742 1445.97 176.211L1445.02 176.797L1444.36 175.848L1444.33 175.863C1444.39 176.607 1444.03 177.577 1443.05 178.177C1442.22 178.678 1440.64 178.966 1439.4 176.927L1437.23 173.386L1438.32 172.728L1440.4 176.15C1440.99 177.107 1441.83 177.683 1442.85 177.064C1443.56 176.631 1443.8 175.791 1443.62 175.076C1443.59 174.907 1443.5 174.714 1443.39 174.523L1441.21 170.957L1442.29 170.299L1444.94 174.637Z" fill="black"/>
<path d="M1438.43 182.8C1438.22 182.427 1438.34 181.977 1438.78 181.744C1439.18 181.54 1439.62 181.694 1439.82 182.077C1440.02 182.469 1439.89 182.932 1439.48 183.145C1439.06 183.368 1438.64 183.2 1438.43 182.8ZM1435.57 174.25L1438.77 180.422L1437.65 181.005L1434.44 174.833L1435.57 174.25Z" fill="black"/>
<path d="M1434.75 180.397C1435.05 181.058 1435.29 181.593 1435.56 182.101L1434.53 182.549L1434.01 181.524L1433.98 181.539C1433.94 182.284 1433.45 183.198 1432.4 183.661C1431.52 184.049 1429.92 184.119 1428.96 181.926L1427.29 178.118L1428.45 177.615L1430.06 181.293C1430.51 182.319 1431.26 183.012 1432.36 182.534C1433.13 182.2 1433.47 181.402 1433.4 180.672C1433.38 180.501 1433.32 180.294 1433.24 180.097L1431.57 176.26L1432.73 175.756L1434.75 180.397Z" fill="black"/>
<path d="M1422.04 187.228C1421.91 186.738 1421.77 186.201 1421.5 185.393L1420.26 181.551C1419.76 180.03 1419.76 179.004 1420.21 178.221C1420.66 177.408 1421.53 176.918 1422.37 176.644C1423.16 176.386 1424.11 176.295 1424.75 176.476L1424.75 177.485C1424.22 177.358 1423.46 177.33 1422.64 177.594C1421.41 177.994 1420.71 178.93 1421.25 180.598L1421.49 181.335L1421.52 181.329C1421.69 180.589 1422.24 179.873 1423.27 179.537C1424.91 179.008 1426.53 180.019 1427.14 181.851C1427.87 184.096 1426.82 185.842 1425.3 186.333C1424.15 186.707 1423.32 186.31 1422.86 185.848L1422.83 185.854L1423.1 186.873L1422.04 187.228ZM1422.44 184.207C1422.51 184.413 1422.58 184.581 1422.69 184.729C1423.14 185.355 1423.91 185.737 1424.78 185.458C1425.93 185.084 1426.44 183.847 1425.94 182.309C1425.52 181.011 1424.51 180.144 1423.21 180.562C1422.48 180.799 1421.96 181.487 1421.95 182.334C1421.94 182.558 1422 182.803 1422.06 183.01L1422.44 184.207Z" fill="black"/>
<path d="M1414.04 191.084L1413.68 189.122L1411.9 189.452L1411.73 188.5L1413.51 188.17L1412.83 184.479C1412.67 183.63 1412.34 183.198 1411.65 183.322C1411.32 183.38 1411.09 183.467 1410.94 183.54L1410.72 182.617C1410.95 182.475 1411.31 182.333 1411.79 182.244C1412.37 182.14 1412.87 182.239 1413.23 182.518C1413.65 182.819 1413.89 183.41 1414.04 184.209L1414.73 187.942L1415.79 187.75L1415.96 188.701L1414.9 188.894L1415.2 190.533L1414.04 191.084Z" fill="black"/>
<path d="M1410.45 192.965L1409.19 193.097L1408.75 188.782L1408.72 188.788C1408.56 189.167 1408.27 189.511 1407.91 189.764C1407.56 190.021 1407.13 190.202 1406.66 190.251C1405.73 190.346 1404.19 189.932 1403.94 187.542L1403.51 183.428L1404.76 183.301L1405.18 187.278C1405.29 188.397 1405.81 189.293 1406.99 189.175C1407.81 189.092 1408.39 188.455 1408.55 187.746C1408.6 187.571 1408.6 187.374 1408.57 187.135L1408.14 182.957L1409.39 182.83L1410.45 192.965Z" fill="black"/>
<path d="M1401.04 186.767C1400.99 185.056 1399.9 184.362 1398.62 184.378C1397.72 184.389 1397.17 184.551 1396.7 184.76L1396.47 183.859C1396.92 183.647 1397.68 183.417 1398.79 183.398C1400.93 183.376 1402.22 184.768 1402.25 186.866C1402.28 188.964 1401.06 190.631 1399.02 190.654C1396.75 190.683 1396.13 188.685 1396.11 187.41C1396.1 187.149 1396.14 186.954 1396.15 186.824L1401.04 186.767ZM1397.34 187.711C1397.34 188.518 1397.69 189.763 1399.11 189.751C1400.39 189.735 1400.94 188.551 1401.03 187.669L1397.34 187.711Z" fill="black"/>
<path d="M1390.26 183.141L1389.58 189.099L1390.56 189.213L1390.45 190.173L1389.47 190.059L1389.43 190.392C1389.32 191.365 1389.01 192.23 1388.36 192.716C1387.84 193.116 1387.19 193.237 1386.6 193.169C1386.16 193.114 1385.78 192.978 1385.55 192.846L1385.83 191.897C1386 192.007 1386.26 192.103 1386.61 192.142C1387.69 192.262 1388.06 191.351 1388.18 190.296L1388.22 189.928L1386.55 189.741L1386.66 188.781L1388.33 188.968L1389.01 183.01L1390.26 183.141Z" fill="black"/>
<path d="M1380.31 183.498C1380.44 182.79 1380.54 182.165 1380.6 181.625L1381.7 181.829L1381.57 182.958L1381.59 182.965C1382.02 182.476 1382.88 181.892 1384.09 182.12C1385.17 182.317 1386.34 183.157 1385.89 185.555L1385.14 189.551L1383.9 189.316L1384.61 185.528C1384.85 184.225 1384.62 183.273 1383.49 183.066C1382.65 182.906 1381.96 183.381 1381.64 183.89C1381.54 184.059 1381.45 184.275 1381.4 184.501L1380.62 188.697L1379.38 188.462L1380.31 183.498Z" fill="black"/>
<path d="M1375.04 189.405L1375.55 187.481L1373.8 187.01L1374.05 186.081L1375.8 186.551L1376.78 182.924C1377 182.086 1376.89 181.551 1376.21 181.369C1375.9 181.285 1375.64 181.266 1375.48 181.258L1375.67 180.327C1375.93 180.299 1376.33 180.327 1376.81 180.448C1377.37 180.608 1377.79 180.901 1377.98 181.318C1378.23 181.771 1378.2 182.4 1377.99 183.199L1377 186.865L1378.05 187.146L1377.8 188.076L1376.76 187.79L1376.33 189.401L1375.04 189.405Z" fill="black"/>
<path d="M1368.28 179.792C1368.52 179.112 1368.72 178.512 1368.86 177.983L1369.92 178.358L1369.61 179.454L1369.64 179.461C1370.14 179.041 1371.08 178.598 1372.25 179.009C1373.28 179.376 1374.31 180.375 1373.49 182.683L1372.13 186.522L1370.94 186.104L1372.22 182.476C1372.66 181.225 1372.58 180.255 1371.49 179.874C1370.69 179.596 1369.94 179.95 1369.54 180.403C1369.41 180.557 1369.29 180.758 1369.22 180.977L1367.8 185.009L1366.6 184.591L1368.28 179.792Z" fill="black"/>
<path d="M1365.24 181.636C1364.91 182.39 1364.64 183.034 1364.42 183.648L1363.41 183.202L1363.92 181.935L1363.86 181.912C1363.2 182.639 1362.26 182.872 1361.48 182.532C1361.35 182.475 1361.27 182.425 1361.18 182.344L1361.65 181.251C1361.76 181.33 1361.87 181.392 1362.03 181.464C1362.85 181.823 1363.7 181.463 1364.24 180.666C1364.33 180.523 1364.44 180.349 1364.52 180.146L1366.02 176.75L1367.16 177.253L1365.24 181.636Z" fill="black"/>
<path d="M1361.02 178.008C1361.8 176.475 1361.16 175.35 1360.04 174.756C1359.24 174.331 1358.68 174.223 1358.17 174.177L1358.4 173.278C1358.88 173.309 1359.67 173.464 1360.65 173.972C1362.54 174.97 1363.02 176.81 1362.03 178.673C1361.06 180.53 1359.19 181.411 1357.39 180.467C1355.38 179.402 1355.78 177.356 1356.38 176.225C1356.51 175.998 1356.62 175.833 1356.69 175.725L1361.02 178.008ZM1357.32 177.079C1356.93 177.781 1356.65 179.047 1357.91 179.715C1359.04 180.312 1360.09 179.533 1360.58 178.803L1357.32 177.079Z" fill="black"/>
<path d="M1346.3 177.964L1354.47 166.141L1355.26 166.69L1347.09 178.513L1346.3 177.964Z" fill="black"/>
<path d="M1347.58 165.502L1347.06 166.086L1345.69 166.152C1342.39 166.272 1340.78 166.459 1339.84 167.504C1339.2 168.212 1338.97 169.179 1340.01 170.104C1340.64 170.667 1341.46 170.816 1342.02 170.825L1341.73 171.758C1340.89 171.735 1339.92 171.428 1339.12 170.707C1337.61 169.377 1337.89 167.775 1338.78 166.772C1339.93 165.48 1341.79 165.269 1344.52 165.153L1345.54 165.13L1345.56 165.107L1342.41 162.32L1343.11 161.531L1347.58 165.502Z" fill="black"/>
<path d="M1339.59 157.882L1337.74 159.625L1340.72 162.787L1340.11 163.36L1332.91 164.42L1331.97 163.424L1336.2 159.442L1335.31 158.487L1336.03 157.804L1336.92 158.759L1338.77 157.016L1339.59 157.882ZM1337.01 160.313L1334.74 162.455C1334.38 162.794 1334.02 163.116 1333.64 163.431L1333.67 163.459C1334.26 163.295 1334.72 163.186 1335.21 163.079L1339.1 162.562L1339.12 162.54L1337.01 160.313Z" fill="black"/>
<path d="M1338.27 155.591L1327.65 159.024L1327.03 158.262L1337.67 154.841L1338.27 155.591Z" fill="black"/>
<path d="M1323.28 152.084L1323.96 151.609L1333.28 150.106L1334.02 151.182L1324.91 152.558L1324.88 152.572L1327.49 156.336L1326.63 156.933L1323.28 152.084Z" fill="black"/>
<path d="M1319.39 145.936C1318.96 145.301 1318.49 144.522 1318.05 143.633C1317.26 142.019 1317.09 140.681 1317.43 139.578C1317.76 138.454 1318.55 137.514 1319.96 136.832C1321.38 136.146 1322.76 136.025 1323.95 136.443C1325.16 136.864 1326.25 137.992 1327.07 139.686C1327.46 140.492 1327.75 141.179 1327.96 141.776L1319.39 145.936ZM1326.55 141.075C1326.46 140.771 1326.27 140.349 1326.06 139.906C1324.89 137.502 1322.92 136.848 1320.56 137.99C1318.49 138.973 1317.76 140.769 1318.91 143.148C1319.2 143.723 1319.47 144.145 1319.68 144.412L1326.55 141.075Z" fill="black"/>
<path d="M1320.78 133.76C1322.37 133.149 1322.66 131.886 1322.23 130.697C1321.92 129.844 1321.58 129.387 1321.23 129.013L1322.01 128.498C1322.35 128.843 1322.82 129.489 1323.21 130.531C1323.94 132.544 1323.05 134.229 1321.08 134.944C1319.11 135.664 1317.13 135.065 1316.44 133.161C1315.67 131.031 1317.35 129.775 1318.54 129.338C1318.79 129.25 1318.99 129.204 1319.11 129.175L1320.78 133.76ZM1318.65 130.581C1317.89 130.841 1316.84 131.596 1317.32 132.933C1317.76 134.13 1319.06 134.254 1319.92 134.057L1318.65 130.581Z" fill="black"/>
<path d="M1320.57 127.193C1320.7 126.773 1320.78 126.064 1320.62 125.451C1320.37 124.564 1319.81 124.276 1319.26 124.421C1318.67 124.58 1318.45 125.019 1318.37 126.007C1318.27 127.336 1317.76 128.083 1316.96 128.303C1315.88 128.604 1314.75 127.972 1314.35 126.531C1314.16 125.856 1314.2 125.205 1314.31 124.773L1315.29 124.831C1315.19 125.141 1315.11 125.688 1315.28 126.309C1315.47 127.027 1316 127.317 1316.5 127.176C1317.05 127.022 1317.19 126.554 1317.28 125.583C1317.4 124.296 1317.82 123.545 1318.82 123.266C1320 122.942 1321.09 123.62 1321.52 125.217C1321.72 125.952 1321.73 126.677 1321.59 127.227L1320.57 127.193Z" fill="black"/>
<path d="M1311.37 121.815C1311.78 121.709 1312.19 121.951 1312.29 122.442C1312.39 122.875 1312.12 123.26 1311.7 123.35C1311.27 123.444 1310.86 123.194 1310.76 122.74C1310.65 122.27 1310.93 121.902 1311.37 121.815ZM1320.37 121.296L1313.57 122.765L1313.3 121.527L1320.1 120.058L1320.37 121.296Z" fill="black"/>
<path d="M1311.93 113.252C1312.44 113.205 1313 113.158 1313.83 113.031L1317.83 112.447C1319.42 112.219 1320.42 112.391 1321.12 112.971C1321.85 113.551 1322.19 114.484 1322.31 115.352C1322.43 116.178 1322.37 117.114 1322.08 117.724L1321.08 117.548C1321.3 117.053 1321.45 116.301 1321.32 115.446C1321.14 114.168 1320.33 113.328 1318.6 113.578L1317.83 113.689L1317.84 113.718C1318.54 114.011 1319.15 114.674 1319.31 115.747C1319.55 117.455 1318.28 118.883 1316.38 119.165C1314.05 119.503 1312.5 118.174 1312.27 116.599C1312.1 115.402 1312.62 114.658 1313.16 114.274L1313.15 114.244L1312.11 114.337L1311.93 113.252ZM1314.84 114.151C1314.63 114.185 1314.44 114.227 1314.29 114.305C1313.6 114.638 1313.09 115.338 1313.22 116.25C1313.39 117.446 1314.53 118.151 1316.12 117.918C1317.47 117.725 1318.5 116.878 1318.3 115.52C1318.19 114.753 1317.61 114.127 1316.77 113.971C1316.54 113.933 1316.29 113.936 1316.08 113.97L1314.84 114.151Z" fill="black"/>
<path d="M1313.59 110.77C1312.87 110.808 1312.28 110.848 1311.71 110.912L1311.66 109.79L1312.81 109.661L1312.8 109.64C1312.12 109.324 1311.45 108.553 1311.39 107.402C1311.35 106.442 1311.85 104.922 1314.23 104.809L1318.38 104.616L1318.44 105.879L1314.43 106.068C1313.31 106.119 1312.39 106.582 1312.45 107.776C1312.48 108.607 1313.11 109.231 1313.82 109.407C1313.98 109.458 1314.2 109.474 1314.42 109.462L1318.6 109.262L1318.65 110.526L1313.59 110.77Z" fill="black"/>
<path d="M1309.56 101.143C1309.99 101.138 1310.33 101.461 1310.32 101.964C1310.31 102.411 1309.96 102.716 1309.52 102.712C1309.08 102.705 1308.74 102.368 1308.75 101.908C1308.76 101.427 1309.11 101.127 1309.56 101.143ZM1318.44 102.744L1311.48 102.59L1311.5 101.327L1318.46 101.48L1318.44 102.744Z" fill="black"/>
<path d="M1313.45 99.0974C1312.73 99.0328 1312.15 98.995 1311.57 98.9869L1311.66 97.8689L1312.81 97.903L1312.82 97.8768C1312.19 97.4765 1311.62 96.6088 1311.73 95.4646C1311.81 94.5059 1312.52 93.0677 1314.89 93.2818L1319.03 93.6605L1318.92 94.9213L1314.92 94.5598C1313.8 94.4637 1312.84 94.7879 1312.73 95.9796C1312.66 96.8087 1313.18 97.5114 1313.86 97.7842C1314.01 97.8525 1314.23 97.9038 1314.44 97.9254L1318.61 98.2981L1318.49 99.5588L1313.45 99.0974Z" fill="black"/>
<path d="M1313.57 84.6395C1314.06 84.7645 1314.6 84.8952 1315.43 85.0556L1319.4 85.8096C1320.97 86.1105 1321.87 86.5975 1322.34 87.3766C1322.84 88.1626 1322.85 89.1624 1322.68 90.0228C1322.53 90.8438 1322.16 91.7074 1321.69 92.1911L1320.8 91.6993C1321.16 91.2993 1321.56 90.6401 1321.71 89.7976C1321.96 88.5292 1321.47 87.472 1319.75 87.1372L1318.99 86.9896L1318.98 87.0158C1319.55 87.5186 1319.91 88.3468 1319.71 89.4071C1319.38 91.1016 1317.71 92.0435 1315.82 91.6846C1313.5 91.2395 1312.48 89.4845 1312.77 87.9126C1312.99 86.7227 1313.73 86.1927 1314.36 86.0092L1314.36 85.983L1313.34 85.7307L1313.57 84.6395ZM1316.02 86.4417C1315.81 86.3986 1315.63 86.384 1315.45 86.4073C1314.68 86.4964 1313.97 86.9941 1313.81 87.8973C1313.59 89.0872 1314.43 90.119 1316.01 90.4234C1317.35 90.6778 1318.6 90.2122 1318.85 88.8699C1319 88.1095 1318.65 87.3276 1317.91 86.9041C1317.71 86.7956 1317.48 86.7193 1317.26 86.6811L1316.02 86.4417Z" fill="black"/>
<path d="M1314.02 75.7786L1315.92 76.3991L1316.48 74.6773L1317.4 74.9788L1316.83 76.7005L1320.4 77.8736C1321.22 78.1422 1321.76 78.0625 1321.98 77.3922C1322.08 77.0766 1322.12 76.8339 1322.12 76.6663L1323.05 76.9071C1323.07 77.1721 1323.01 77.5576 1322.86 78.0219C1322.68 78.5827 1322.35 78.9718 1321.93 79.1555C1321.47 79.3811 1320.84 79.3114 1320.06 79.0533L1316.45 77.8745L1316.11 78.8997L1315.19 78.5982L1315.53 77.5731L1313.94 77.0498L1314.02 75.7786Z" fill="black"/>
<path d="M1314.07 71.7727L1314.56 70.6054L1318.57 72.2758L1318.58 72.2497C1318.32 71.9215 1318.15 71.5109 1318.11 71.0711C1318.05 70.641 1318.09 70.1748 1318.28 69.734C1318.63 68.8752 1319.74 67.7134 1321.96 68.6369L1325.78 70.2285L1325.29 71.3958L1321.6 69.8607C1320.57 69.429 1319.53 69.4526 1319.08 70.5449C1318.76 71.3025 1319.04 72.1144 1319.59 72.5971C1319.72 72.7308 1319.89 72.8109 1320.11 72.9134L1323.99 74.5273L1323.5 75.6947L1314.07 71.7727Z" fill="black"/>
<path d="M1324.03 66.4213C1325.56 67.2016 1326.69 66.5581 1327.29 65.4402C1327.71 64.6418 1327.83 64.0848 1327.87 63.5758L1328.77 63.8095C1328.74 64.2924 1328.58 65.079 1328.06 66.055C1327.06 67.9487 1325.21 68.4206 1323.36 67.4325C1321.5 66.4445 1320.62 64.5865 1321.57 62.7858C1322.63 60.7765 1324.68 61.1776 1325.81 61.7754C1326.04 61.8992 1326.2 62.0138 1326.32 62.0929L1324.03 66.4213ZM1324.98 62.7231C1324.27 62.3315 1323.01 62.0554 1322.34 63.3067C1321.74 64.4377 1322.52 65.4817 1323.24 65.9838L1324.98 62.7231Z" fill="black"/>
<path d="M1332.38 58.6928L1327.47 55.2513L1326.91 56.0492L1326.13 55.4951L1326.68 54.6972L1326.41 54.5118C1325.61 53.9541 1325 53.2649 1324.88 52.4605C1324.78 51.8094 1324.99 51.1831 1325.33 50.6989C1325.58 50.3316 1325.89 50.077 1326.11 49.9345L1326.82 50.6352C1326.64 50.7359 1326.44 50.9116 1326.23 51.2073C1325.61 52.0863 1326.23 52.8564 1327.1 53.4695L1327.41 53.6786L1328.35 52.2953L1329.14 52.8494L1328.17 54.2257L1333.08 57.6672L1332.38 58.6928Z" fill="black"/>
<path d="M1336.81 49.8118C1337.37 50.2634 1337.86 50.6548 1338.32 50.9666L1337.61 51.8294L1336.69 51.1677L1336.67 51.1904C1336.89 51.8041 1336.99 52.8285 1336.21 53.7902C1335.51 54.6351 1334.22 55.271 1332.33 53.7184L1329.18 51.1417L1329.98 50.162L1332.96 52.611C1333.99 53.447 1334.93 53.6996 1335.66 52.8142C1336.2 52.1591 1336.11 51.3272 1335.82 50.7947C1335.72 50.6204 1335.57 50.4403 1335.39 50.3008L1332.09 47.5917L1332.89 46.612L1336.81 49.8118Z" fill="black"/>
<path d="M1334.17 42.331L1335.61 43.7085L1336.86 42.4011L1337.56 43.0721L1336.31 44.3795L1339.03 46.9736C1339.65 47.5676 1340.17 47.7294 1340.66 47.2231C1340.89 46.9819 1341.03 46.7804 1341.11 46.6338L1341.84 47.2428C1341.75 47.4846 1341.53 47.8196 1341.19 48.1707C1340.78 48.5946 1340.32 48.8071 1339.87 48.7913C1339.35 48.7924 1338.81 48.4595 1338.22 47.8938L1335.48 45.2714L1334.71 46.0476L1334.01 45.3849L1334.76 44.6016L1333.55 43.4502L1334.17 42.331Z" fill="black"/>
<path d="M1345.82 41.0341C1346.3 41.5682 1346.74 42.0359 1347.13 42.4158L1346.3 43.1614L1345.49 42.3684L1345.47 42.3911C1345.59 43.0278 1345.54 44.066 1344.62 44.8857C1343.8 45.6134 1342.43 46.0392 1340.8 44.2131L1338.09 41.1851L1339.03 40.3427L1341.6 43.2073C1342.48 44.1938 1343.38 44.5821 1344.24 43.8221C1344.87 43.2557 1344.91 42.4245 1344.71 41.8523C1344.64 41.6636 1344.52 41.4644 1344.37 41.2891L1341.51 38.1107L1342.46 37.2683L1345.82 41.0341Z" fill="black"/>
<path d="M1345.68 37.474C1345.19 36.8211 1344.74 36.2678 1344.32 35.7823L1345.2 35.1066L1346.07 36.1668L1346.11 36.1298C1345.8 35.1955 1346.05 34.2658 1346.72 33.7444C1346.83 33.656 1346.92 33.608 1347.03 33.5623L1347.76 34.5067C1347.64 34.5655 1347.53 34.6278 1347.39 34.7307C1346.68 35.2712 1346.58 36.1919 1347.02 37.0543C1347.1 37.2085 1347.2 37.3828 1347.34 37.5593L1349.59 40.5074L1348.6 41.2667L1345.68 37.474Z" fill="black"/>
<path d="M1350.92 35.5151C1351.88 36.9304 1353.19 36.9163 1354.24 36.222C1354.99 35.7217 1355.36 35.2956 1355.64 34.8663L1356.32 35.5041C1356.06 35.919 1355.55 36.5253 1354.63 37.1345C1352.84 38.3112 1351 37.8379 1349.84 36.0906C1348.69 34.3349 1348.82 32.2787 1350.51 31.1595C1352.41 29.9074 1354.01 31.2414 1354.71 32.3154C1354.86 32.53 1354.94 32.7139 1355 32.8349L1350.92 35.5151ZM1353.51 32.7143C1353.07 32.0362 1352.1 31.1798 1350.91 31.9638C1349.85 32.6676 1350.03 33.958 1350.43 34.7457L1353.51 32.7143Z" fill="black"/>
</svg>

</a>

</div>
  
</template>

<script>
export default {


    data:function(){
            return{
                spanish:false,
            }
        },
        

props: {
    idioma:{
        type:String
    },
text:{
    type:String,

    required:true,
   
},
}
}
</script>



<style lang="scss" scoped>
@import "../scss/variables.scss";
svg{
    width: 100%;
    height: 100%;
    padding-bottom: 8rem;
   
}
svg:hover{
    
    }
.Title{
    padding-top: 8rem;;
    
    text-align: left;
font-size: 20px;
    width: 170px;
}


</style>